import React from 'react';
import { Route, RouteProps, Switch } from 'react-router-dom';

import ProtectedRoute from './components/ProtectedRoute';
import Page400 from './pages/400';
import Page403 from './pages/403';
import Page404 from './pages/404';
import Page500 from './pages/500';
import Layout from './pages/Layout';
import Home from './pages/Home';
import ClientIndex from './pages/ClientIndex';
import ClientForm from './pages/Client';
import LoanImports from './pages/LoanImports';
import AssociationErrors from './pages/AssociationErrors';
import ImportDocs from './pages/ImportDocs';
import SearchLoans from './pages/SearchLoans';
import LoanErrorIndex from './pages/LoanErrorIndex';
import LoanErrorResolver from './pages/LoanErrorResolver';
import SearcherRetrieval from './pages/SearcherRetrieval';
import BatchVerificationStart from './pages/BatchVerificationStart';
import BatchVerification from './pages/BatchVerification';
import ClientContacts from './components/Admin/ClientContacts';
import AdminTitleCompanies from './components/Admin/TitleCompanies';
import AdminIndex from './pages/AdminIndex';
import DeleteLoan from './components/Admin/DeleteLoan';
import DeleteAssociation from './components/Admin/DeleteAssociation';
import UndoAssociation from './components/Admin/UndoAssociation';
import DeleteDocument from './components/Admin/DeleteDocument';
import DownloadDocuments from './components/DownloadDocuments';
import Reports from './pages/Reports/Index';
import NotFoundsReport from './pages/Reports/NotFounds';
import OutstandingLoans from './pages/Reports/OutstandingLoans';
import NotFounds from './pages/NotFounds';
import ReadyToShip from './pages/ReadyToShip';
import Workflow from './pages/Workflow';
import FlagDocument from './pages/FlagDocuments/FlaggedDocument';
import CreateMatches from './components/Admin/CreateMatches';
import Shipping from './pages/Shipping';
import Document from './components/Documents/Index/Document';
import CheckUploadedDoc from './pages/CheckUploadedDoc';
import Shipments from './components/Shipping/Shipments';
import ShipmentDisplay from './components/Shipping/ShipmentDisplay';
import Investors from './components/Admin/Investors';
import ImportNotesSpreadsheet from './pages/ImportNotesSpreadsheet';
import TitleCompany from './pages/TitleCompany';
import { roleTypes } from './constants';
import ClientLoans from './pages/ClientLoans';
import ClientSingleLoan from './pages/ClientSingleLoan';
import CallCenter from './pages/CallCenter';
import DeterminationOptions from './components/Documents/Index/Determination';
import InvestorsTable from './pages/InvestorsTable';
import Investor from './pages/Investor';
import CallCenterAdminOptions from './components/Admin/CallCenterAdminOptions';
import OneOffEmail from './pages/OneOffEmails';
import PrintCoversheets from './pages/PrintCoversheets';
import InvestorReport from './pages/InvestorReport';
import KnowledgeBase from './pages/KnowledgeBase';
import DocumentsPageWrapper from './components/DocumentsPageWrapper';
import DocumentIntake from './pages/reportsDashboard/TvScreens/DocumentIntake';
import DashboardShipping from './pages/reportsDashboard/TvScreens/Shipping';
import DashboardSorting from './pages/reportsDashboard/TvScreens/Sorting';
import DeterminationPageWrapper from './components/DeterminationPageWrapper';
import GeneralReporting from './pages/reportsDashboard/GeneralReporting';
import AccountRepsPage from './components/AccountRepsPage';
import LandingPage from './pages/reportsDashboard/LandingPage';
import DetailedShippingReport from './pages/reportsDashboard/DetailedShippingReport';
import ClientDocumentIndex2 from './pages/ClientDocumentIndex2';
import BulkLoansNote from './components/Admin/BulkLoansNote';
import BulkShipDocuments from './components/Admin/BulkShipDocuments';
import UserDashboardEventLogs from './pages/clientComponents/UserDashboardEventLogs';
import SalesRepsDashboard from './pages/SalesRepsDashboard/SalesRepsDashboard';
import SalesRepLandingPage from './pages/SalesRepsDashboard/LandingPage';
import { PrintingContextProvider } from './PrintingContext';
import AppVersion from './components/AppVersion';
import TitleCompanyPortalLinks from './pages/TitleCompanyPortalLinks';
import ShippingTrackingReport from './pages/Reports/ShippingTrackingReport';
import LoansReceived from './pages/Reports/LoansReceived';
import WaitingToBeMatchedDrillDownReport from './pages/reportsDashboard/WaitingToBeMatchedDrillDownReport';
import WaitingToBeMatchedAsOfTodayDrillDownReport from './pages/reportsDashboard/WaitingToBeMatchedAsOfTodayDrillDownReport';
import WaitingToBeDeterminedDrillDownReport from './pages/reportsDashboard/WaitingToBeDeterminedDrillDownReport';
import WaitingToBeDeterminedAsOfTodayDrillDownReport from './pages/reportsDashboard/WaitingToBeDeterminedAsOfTodayDrillDownReport';
import WaitingToBeSortedDrillDownReport from './pages/reportsDashboard/WaitingToBeSortedDrillDownReport';
import PendingCorrectionByClient from './pages/reportsDashboard/PendingCorrectionByClient';
import TitleCompaniesPendingAssociation from './pages/reportsDashboard/TitleCompaniesPendingAssociation';
import InvestorsPendingAssociationByClient from './pages/reportsDashboard/InvestorsPendingAssociationByClient';
import CatchAll from './pages/DocumentCatchAll/Index';
import TitlePortalLinks from './components/Admin/TitlePortalLinks';
import DigitalDelivery from './pages/DigitalDelivery';
import TitleCompanies from './components/TitleCompanies';
import Staple from './pages/Staple';
import Sort from './pages/Sort';
import ShipRequests from './components/Shipping/ShipRequests';
import RevenueBreakdownReport from './pages/RevenueBreakdownReport';
import CorrectionsPipeline from './pages/Reports/CorrectionPipeline/GenerateReport';
import ImportDocumentNotes from './pages/ImportDocumentNotes';
import WaitingToBeBroadMatchedDrillDownReport from './pages/reportsDashboard/WaitingToBeBroadMatchedDrillDownReport';
import EmbedContainer from './components/PowerBI/EmbedContainer';
import LenderAliases from './pages/LenderAliases';
import CorrectionFulfillment from './pages/CorrectionFulfillment';
import OutreachEscalations from './pages/OutreachEscalations';
import ClientPortalUserManagement from './pages/ClientPortalUserManagement';
import CorrectionCreditsReport from './pages/Reports/CorrectionCreditsReport';
import Payments from './pages/Payments';
import ClosingPackages from './pages/ClosingPackages/ClosingPackages';
import EncompassTools from './pages/EncompassTools';
import AuditQueueByClientDrillDownReport from './pages/Reports/AuditQueueByClientDrillDownReport';
import NewDocumentType from './pages/NewDocumentType';
import TitlePortalOutreach from './pages/TitlePortalOutreach';
import CollateralServices from './pages/CollateralServices/CollateralServices';

interface CustomRouteProps extends RouteProps {
  protected?: boolean;
  path: string;
  component: React.ComponentType<any>;
  noLayout?: boolean;
  authorizedRoles?: string[];
  title?: string;
}

const {
  AccountRep,
  AccountsPayable,
  Admin,
  BasicUser,
  CallCenterAdmin,
  CallCenterUser,
  CallCenterUserWithShipping,
  ClientAccess,
  ClientAdmin,
  CorrectionAdmin,
  ExecutiveManagement,
  Data,
  DataAssociation,
  Dev,
  Digitals,
  DigitalsWithImportErrors,
  DigitalTeamLead,
  MatchAndSort,
  Online,
  ProcessingAdmin,
  QCTeamLead,
  SalesManager,
  SalesRep,
  Scanner,
  ScannerWithNotFound,
  ScanningTeamLead,
  ShippingTeamLead,
  TitlePortalSupport,
} = roleTypes;

const routes: CustomRouteProps[] = [
  {
    path: '/',
    exact: true,
    protected: true,
    title: 'Home',
    component: Home,
  },
  {
    path: '/clients',
    exact: true,
    protected: true,
    component: ClientIndex,
    title: 'Clients',
    authorizedRoles: [BasicUser, CallCenterAdmin, Admin, Dev, ClientAccess, AccountRep],
  },
  {
    path: '/clients/new-client',
    exact: true,
    protected: true,
    component: ClientForm,
    title: 'Client',
    authorizedRoles: [Admin, Dev, ClientAdmin],
  },
  {
    path: '/clients/:clientId(\\d+)',
    exact: true,
    protected: true,
    component: ClientForm,
    title: 'Client Edit',
    authorizedRoles: [Admin, BasicUser, AccountRep, CallCenterAdmin, Dev, ClientAdmin],
  },
  {
    path: '/clients/:clientId(\\d+)/loans',
    exact: true,
    protected: true,
    component: ClientLoans,
    title: 'Client Loans',
    authorizedRoles: [BasicUser, AccountRep, CallCenterAdmin, Admin, Dev],
  },
  {
    path: '/loans/:loanId(\\d+)',
    exact: true,
    protected: true,
    component: ClientSingleLoan,
    title: 'Loan',
    authorizedRoles: [BasicUser, AccountRep, CallCenterAdmin, Admin, Dev],
  },
  {
    path: '/loan-imports',
    exact: true,
    protected: true,
    component: LoanImports,
    title: 'Loan Imports',
    authorizedRoles: [BasicUser, AccountRep, CallCenterAdmin, Data, Admin, Dev],
  },
  {
    path: '/spreadsheets/notes',
    exact: true,
    protected: true,
    component: ImportNotesSpreadsheet,
    title: 'Note Imports',
    authorizedRoles: [
      CallCenterAdmin,
      CallCenterUser,
      CallCenterUserWithShipping,
      BasicUser,
      AccountRep,
      Admin,
      Dev,
    ],
  },
  {
    path: '/outreach',
    exact: true,
    protected: true,
    component: CallCenter,
    title: 'Outreach',
    authorizedRoles: [
      CallCenterAdmin,
      CallCenterUser,
      CallCenterUserWithShipping,
      BasicUser,
      AccountRep,
      Admin,
      Dev,
    ],
  },
  {
    path: '/outreach-escalations',
    exact: true,
    protected: true,
    component: OutreachEscalations,
    title: 'Outreach Escalations',
    authorizedRoles: [
      CallCenterAdmin,
      CallCenterUser,
      CallCenterUserWithShipping,
      BasicUser,
      AccountRep,
      Admin,
      Dev,
    ],
  },
  {
    path: '/title-portal-outreach',
    exact: true,
    protected: true,
    component: TitlePortalOutreach,
    title: 'Title Portal Outreach',
    authorizedRoles: [TitlePortalSupport, AccountRep, Admin, Dev],
  },
  {
    path: '/admin/outreach',
    exact: true,
    protected: true,
    component: CallCenterAdminOptions,
    title: 'Outreach',
    authorizedRoles: [CallCenterAdmin, Admin, Dev],
  },
  {
    path: '/title-companies',
    exact: true,
    protected: true,
    component: TitleCompanies,
    title: 'Title Companies',
    authorizedRoles: [
      BasicUser,
      AccountRep,
      CallCenterAdmin,
      Admin,
      Dev,
      DataAssociation,
      TitlePortalSupport,
    ],
  },
  {
    path: '/title-companies/:titleCompanyId(\\d+)',
    exact: true,
    protected: true,
    component: TitleCompany,
    title: 'Title Company',
    authorizedRoles: [
      BasicUser,
      AccountRep,
      CallCenterAdmin,
      Admin,
      Dev,
      DataAssociation,
      TitlePortalSupport,
    ],
  },
  {
    path: '/errors/associations',
    exact: true,
    protected: true,
    component: AssociationErrors,
    title: 'Association Errors',
    authorizedRoles: [
      BasicUser,
      AccountRep,
      CallCenterAdmin,
      Data,
      Admin,
      Dev,
      DigitalsWithImportErrors,
    ],
  },
  {
    path: '/errors/loans',
    exact: true,
    protected: true,
    component: LoanErrorIndex,
    title: 'Loan Errors',
    authorizedRoles: [
      BasicUser,
      AccountRep,
      CallCenterAdmin,
      Data,
      Admin,
      Dev,
      DigitalsWithImportErrors,
    ],
  },
  {
    path: '/errors/loans/:clientId',
    exact: true,
    protected: true,
    component: LoanErrorResolver,
    authorizedRoles: [BasicUser, AccountRep, CallCenterAdmin, Admin, Dev],
  },
  {
    path: '/documents/import/physical',
    exact: true,
    protected: true,
    component: ImportDocs,
    title: 'Document Imports - Physical',
    authorizedRoles: [
      BasicUser,
      AccountRep,
      CallCenterAdmin,
      Online,
      Admin,
      Dev,
      Digitals,
      DigitalsWithImportErrors,
    ],
  },
  {
    path: '/documents/import/digital',
    exact: true,
    protected: true,
    component: ImportDocs,
    title: 'Document Imports - Digital',
    authorizedRoles: [
      BasicUser,
      AccountRep,
      CallCenterAdmin,
      Online,
      Admin,
      Dev,
      Digitals,
      DigitalsWithImportErrors,
    ],
  },
  {
    path: '/documents/download',
    exact: true,
    protected: true,
    component: DownloadDocuments,
    title: 'Download Documents',
    authorizedRoles: [BasicUser, AccountRep, CallCenterAdmin, Data, Admin, Dev],
  },
  {
    path: '/documents/match',
    exact: true,
    protected: true,
    component: NotFounds,
    title: 'Match',
    authorizedRoles: [
      BasicUser,
      AccountRep,
      CallCenterAdmin,
      Admin,
      Dev,
      MatchAndSort,
      ScannerWithNotFound,
      Online,
    ],
  },
  {
    path: '/documents/readytoship',
    exact: true,
    protected: true,
    component: ReadyToShip,
    authorizedRoles: [BasicUser, AccountRep, CallCenterAdmin, Admin, Dev, MatchAndSort],
  },
  {
    path: '/sort',
    exact: true,
    protected: true,
    component: Sort,
    title: 'Sort',
    authorizedRoles: [BasicUser, AccountRep, CallCenterAdmin, Admin, Dev, MatchAndSort],
  },
  {
    path: '/documents/checkuploaded',
    exact: true,
    protected: true,
    component: CheckUploadedDoc,
    title: 'Staple (Deprecated)',
    authorizedRoles: [
      BasicUser,
      AccountRep,
      CallCenterAdmin,
      Admin,
      Dev,
      Scanner,
      ScannerWithNotFound,
    ],
  },
  {
    path: '/staple',
    exact: true,
    protected: true,
    component: Staple,
    title: 'Staple',
    authorizedRoles: [
      BasicUser,
      AccountRep,
      CallCenterAdmin,
      Admin,
      Dev,
      Scanner,
      ScannerWithNotFound,
    ],
  },
  {
    path: '/search',
    exact: true,
    protected: true,
    component: SearchLoans,
    title: 'Close Loan',
    authorizedRoles: [BasicUser, AccountRep, CallCenterAdmin, Admin, Dev],
  },
  {
    path: '/reports-dashboard/document-intake',
    exact: true,
    protected: true,
    component: DocumentIntake,
    title: 'Document Intake Dashboard',
    authorizedRoles: [
      Admin,
      ProcessingAdmin,
      Dev,
      QCTeamLead,
      ShippingTeamLead,
      ScanningTeamLead,
      DigitalTeamLead,
    ],
    noLayout: true,
  },
  {
    path: '/reports-dashboard/shipping',
    exact: true,
    protected: true,
    component: DashboardShipping,
    title: 'Ship Dashboard',
    authorizedRoles: [
      Admin,
      ProcessingAdmin,
      Dev,
      QCTeamLead,
      ShippingTeamLead,
      ScanningTeamLead,
      DigitalTeamLead,
    ],
    noLayout: true,
  },
  {
    path: '/reports-dashboard/sorting',
    title: 'Sort Dashboard',
    exact: true,
    protected: true,
    component: DashboardSorting,
    authorizedRoles: [
      Admin,
      ProcessingAdmin,
      Dev,
      QCTeamLead,
      ShippingTeamLead,
      ScanningTeamLead,
      DigitalTeamLead,
    ],
    noLayout: true,
  },
  {
    path: '/reports-dashboard/landing-page',
    exact: true,
    protected: true,
    component: LandingPage,
    title: 'Reports',
    authorizedRoles: [
      Admin,
      ProcessingAdmin,
      Dev,
      QCTeamLead,
      ShippingTeamLead,
      ScanningTeamLead,
      DigitalTeamLead,
      CorrectionAdmin,
      AccountRep,
    ],
  },
  {
    path: '/reports-dashboard/general-reporting',
    exact: true,
    protected: true,
    component: GeneralReporting,
    title: 'Reports',
    authorizedRoles: [
      Admin,
      ProcessingAdmin,
      Dev,
      QCTeamLead,
      AccountRep,
      ShippingTeamLead,
      ScanningTeamLead,
      DigitalTeamLead,
      CorrectionAdmin,
    ],
  },
  {
    path: '/reports-dashboard/general-reporting/shipping',
    exact: true,
    protected: true,
    component: DetailedShippingReport,
    title: 'Shipping Report',
    authorizedRoles: [Admin, ProcessingAdmin, Dev, ShippingTeamLead],
  },
  {
    path: '/reports-dashboard/general-reporting/sorting',
    exact: true,
    protected: true,
    component: WaitingToBeSortedDrillDownReport,
    title: 'Sorting Report',
    authorizedRoles: [Admin, ProcessingAdmin, Dev, QCTeamLead],
  },
  {
    path: '/reports-dashboard/general-reporting/determination',
    exact: true,
    protected: true,
    component: WaitingToBeDeterminedDrillDownReport,
    title: 'Audit Drilldown',
    authorizedRoles: [Admin, Dev, DigitalTeamLead],
  },
  {
    path: '/reports-dashboard/general-reporting/determinationByClient/:clientId',
    exact: true,
    protected: true,
    component: AuditQueueByClientDrillDownReport,
    title: 'Audit Drill Down By Client',
    authorizedRoles: [Admin, Dev, DigitalTeamLead],
  },
  {
    path: '/reports-dashboard/general-reporting/determinationAsOfToday',
    exact: true,
    protected: true,
    component: WaitingToBeDeterminedAsOfTodayDrillDownReport,
    title: 'Audit Drilldown',
    authorizedRoles: [Admin, Dev, DigitalTeamLead],
  },
  {
    path: '/reports-dashboard/general-reporting/matching',
    exact: true,
    protected: true,
    component: WaitingToBeMatchedDrillDownReport,
    title: 'Matching Drilldown',
    authorizedRoles: [Admin, Dev, DigitalTeamLead],
  },
  {
    path: '/reports-dashboard/general-reporting/matchingAsOfToday',
    exact: true,
    protected: true,
    component: WaitingToBeMatchedAsOfTodayDrillDownReport,
    title: 'Matching Drilldown',
    authorizedRoles: [Admin, Dev, DigitalTeamLead],
  },
  {
    path: '/reports-dashboard/general-reporting/broadMatching',
    exact: true,
    protected: true,
    component: WaitingToBeBroadMatchedDrillDownReport,
    title: 'Broad Matching Drilldown',
    authorizedRoles: [Admin, Dev, DigitalTeamLead],
  },
  {
    path: '/reports-dashboard/general-reporting/pending-correction',
    exact: true,
    protected: true,
    component: PendingCorrectionByClient,
    title: 'Pending Correction Report',
    authorizedRoles: [Admin, Dev, DigitalTeamLead],
  },
  {
    path: '/reports-dashboard/general-reporting/title-companies-pending-association',
    exact: true,
    protected: true,
    component: TitleCompaniesPendingAssociation,
    title: 'Title Association Report',
    authorizedRoles: [Admin, Dev, DigitalTeamLead],
  },
  {
    path: '/reports-dashboard/general-reporting/investors-pending-association',
    exact: true,
    protected: true,
    component: InvestorsPendingAssociationByClient,
    title: 'Investor Association Report',
    authorizedRoles: [Admin, Dev, DigitalTeamLead],
  },
  {
    path: '/workflow',
    exact: true,
    protected: true,
    component: Workflow,
    title: 'Workflow',
    authorizedRoles: [BasicUser, AccountRep, CallCenterAdmin, Admin, Dev],
  },
  {
    path: '/shipping',
    exact: true,
    protected: true,
    component: Shipping,
    title: 'Shipping',
    authorizedRoles: [
      BasicUser,
      AccountRep,
      CallCenterAdmin,
      CallCenterUserWithShipping,
      Admin,
      Dev,
    ],
  },
  {
    path: '/shipping/shipments',
    exact: true,
    protected: true,
    component: Shipments,
    title: 'Shipments',
    authorizedRoles: [
      BasicUser,
      AccountRep,
      CallCenterAdmin,
      CallCenterUserWithShipping,
      Admin,
      Dev,
    ],
  },
  {
    path: '/shipping/shipments/:shippingId',
    exact: true,
    protected: true,
    component: ShipmentDisplay,
    title: 'Shipment',
    authorizedRoles: [
      BasicUser,
      AccountRep,
      CallCenterAdmin,
      CallCenterUserWithShipping,
      Admin,
      Dev,
    ],
  },
  {
    path: '/ship-a-copy',
    exact: true,
    protected: true,
    component: ShipRequests,
    title: 'Ship Requests',
    authorizedRoles: [Admin, Dev, ProcessingAdmin],
  },
  {
    path: '/reports',
    exact: true,
    protected: true,
    component: Reports,
    title: 'Reports',
    authorizedRoles: [BasicUser, AccountRep, CallCenterAdmin, Admin, Dev],
  },
  {
    path: '/reports/outstanding-loans',
    exact: true,
    protected: true,
    component: OutstandingLoans,
    title: 'Outstanding Loans Report',
    authorizedRoles: [BasicUser, AccountRep, CallCenterAdmin, Admin, Dev],
  },
  {
    path: '/reports/not-founds',
    exact: true,
    protected: true,
    component: NotFoundsReport,
    title: 'Not Found Report',
    authorizedRoles: [BasicUser, AccountRep, CallCenterAdmin, Admin, Dev],
  },
  {
    path: '/reports/shipment-tracking',
    exact: true,
    protected: true,
    component: ShippingTrackingReport,
    title: 'Shipment Tracking Report',
    authorizedRoles: [BasicUser, AccountRep, CallCenterAdmin, Admin, Dev],
  },
  {
    path: '/reports/correction-credits',
    exact: true,
    protected: true,
    component: CorrectionCreditsReport,
    title: 'Correction Credits Report',
    authorizedRoles: [BasicUser, AccountRep, CallCenterAdmin, Admin, Dev],
  },
  {
    path: '/reports/loans-received-count',
    exact: true,
    protected: true,
    component: LoansReceived,
    title: 'Loans Received Report',
    authorizedRoles: [Admin, Dev],
  },
  {
    path: '/reports/corrections-pipeline',
    exact: true,
    protected: true,
    component: CorrectionsPipeline,
    title: 'Corrections Report',
    authorizedRoles: [Admin, Dev, AccountRep],
  },
  {
    path: '/searcher-retrieval',
    exact: true,
    protected: true,
    component: SearcherRetrieval,
    title: 'Searcher Retrieval',
    authorizedRoles: [BasicUser, AccountRep, CallCenterAdmin, Admin, Dev],
  },
  {
    path: '/one-off-email',
    exact: true,
    protected: true,
    component: OneOffEmail,
    authorizedRoles: [Dev],
  },
  {
    path: '/batch-verification-start',
    exact: true,
    protected: true,
    component: BatchVerificationStart,
    title: 'Batch verification',
    authorizedRoles: [BasicUser, AccountRep, CallCenterAdmin, Admin, Dev],
  },
  {
    path: '/batch-verification/:barcode',
    exact: true,
    protected: true,
    component: BatchVerification,
    title: 'Batch verification',
    authorizedRoles: [BasicUser, AccountRep, CallCenterAdmin, Admin, Dev],
  },
  {
    path: '/documents',
    exact: true,
    protected: true,
    component: Document,
    title: 'Document Index',
    authorizedRoles: [
      BasicUser,
      AccountRep,
      CallCenterAdmin,
      Admin,
      Dev,
      Scanner,
      ScannerWithNotFound,
    ],
  },
  {
    path: '/documents-old',
    exact: true,
    protected: true,
    component: ClientDocumentIndex2,
    title: 'Document Index (Deprecated)',
    authorizedRoles: [
      BasicUser,
      AccountRep,
      CallCenterAdmin,
      Admin,
      Dev,
      Scanner,
      ScannerWithNotFound,
    ],
  },
  {
    path: '/documents/:documentId(\\d+)',
    exact: true,
    protected: true,
    component: DocumentsPageWrapper,
    title: 'Document',
    authorizedRoles: [
      BasicUser,
      AccountRep,
      CallCenterAdmin,
      Online,
      Admin,
      Dev,
      Scanner,
      ScannerWithNotFound,
    ],
  },
  {
    path: '/determine-document',
    exact: true,
    protected: true,
    component: DeterminationPageWrapper,
    title: 'Audit',
    authorizedRoles: [
      BasicUser,
      AccountRep,
      CallCenterAdmin,
      Online,
      Admin,
      Dev,
      Digitals,
      DigitalsWithImportErrors,
    ],
  },
  {
    path: '/new-det-doc',
    exact: true,
    protected: true,
    title: 'Audit',
    component: DeterminationPageWrapper,
  },
  {
    path: '/new-documents/:documentId',
    exact: true,
    protected: true,
    component: DocumentsPageWrapper,
    title: 'Document',
    authorizedRoles: [
      BasicUser,
      AccountRep,
      CallCenterAdmin,
      Online,
      Admin,
      Dev,
      Scanner,
      ScannerWithNotFound,
    ],
  },
  {
    path: '/investors',
    exact: true,
    protected: true,
    component: InvestorsTable,
    title: 'Investors',
    authorizedRoles: [BasicUser, AccountRep, CallCenterAdmin, Admin, Dev, DataAssociation],
  },
  {
    path: '/investors/:investorId(\\d+)',
    exact: true,
    protected: true,
    component: Investor,
    title: 'Investor',
    authorizedRoles: [BasicUser, AccountRep, CallCenterAdmin, Admin, Dev, DataAssociation],
  },
  {
    path: '/knowledge-base',
    exact: true,
    protected: true,
    component: KnowledgeBase,
    title: 'Knowledge Base',
  },
  {
    path: '/admin',
    exact: true,
    protected: true,
    component: AdminIndex,
    title: 'Admin',
    authorizedRoles: [BasicUser, AccountRep, CallCenterAdmin, Admin, Dev],
  },
  {
    path: '/admin/clientcontacts',
    exact: true,
    protected: true,
    component: ClientContacts,
    title: 'Client Contacts',
    authorizedRoles: [BasicUser, AccountRep, CallCenterAdmin, Admin, Dev],
  },
  {
    path: '/admin/titlecompanies',
    exact: true,
    protected: true,
    component: AdminTitleCompanies,
    title: 'Title Companies',
    authorizedRoles: [BasicUser, AccountRep, CallCenterAdmin, Admin, Dev],
  },
  {
    path: '/admin/deleteLoan',
    exact: true,
    protected: true,
    component: DeleteLoan,
    title: 'Delete Loan',
    authorizedRoles: [BasicUser, AccountRep, CallCenterAdmin, Admin, Dev],
  },
  {
    path: '/admin/deleteassociation',
    exact: true,
    protected: true,
    component: DeleteAssociation,
    title: 'Delete Association',
    authorizedRoles: [Admin, Dev, DataAssociation],
  },
  {
    path: '/admin/undoassociation',
    exact: true,
    protected: true,
    component: UndoAssociation,
    title: 'Undo Association',
    authorizedRoles: [Admin, Dev, DataAssociation],
  },
  {
    path: '/admin/deletedocument',
    exact: true,
    protected: true,
    component: DeleteDocument,
    title: 'Delete Document',
    authorizedRoles: [BasicUser, AccountRep, CallCenterAdmin, Admin, Dev],
  },
  {
    path: '/admin/flagdocument',
    exact: true,
    protected: true,
    component: FlagDocument,
    title: 'Flag Document',
    authorizedRoles: [BasicUser, AccountRep, CallCenterAdmin, Admin, Dev],
  },
  {
    path: '/admin/creatematches',
    exact: true,
    protected: true,
    component: CreateMatches,
    title: 'Match',
    authorizedRoles: [BasicUser, AccountRep, CallCenterAdmin, Admin, Dev],
  },
  {
    path: '/admin/investors',
    exact: true,
    protected: true,
    component: Investors,
    title: 'Investor',
    authorizedRoles: [BasicUser, AccountRep, CallCenterAdmin, Admin, Dev],
  },
  {
    path: '/admin/bulk-loan-note',
    exact: true,
    protected: true,
    component: BulkLoansNote,
    title: 'Bulk Loan Note',
    authorizedRoles: [BasicUser, AccountRep, CallCenterAdmin, Admin, Dev],
  },
  {
    path: '/admin/bulk-ship-documents',
    exact: true,
    protected: true,
    component: BulkShipDocuments,
    title: 'Bulk Ship',
    authorizedRoles: [BasicUser, AccountRep, CallCenterAdmin, Admin, Dev],
  },
  {
    path: '/admin/digital-delivery-settings',
    exact: true,
    protected: true,
    component: DigitalDelivery,
    title: 'Digital Delivery Settings',
    authorizedRoles: [Admin, Dev],
  },
  {
    path: '/admin/revenue-breakdown-report',
    exact: true,
    protected: true,
    component: RevenueBreakdownReport,
    title: 'Revenue Breakdown Report',
    authorizedRoles: [Admin, ExecutiveManagement, Dev],
  },
  {
    path: '/admin/payments',
    exact: true,
    protected: true,
    component: Payments,
    title: 'Payments',
    authorizedRoles: [ExecutiveManagement, AccountsPayable, Dev],
  },
  {
    path: '/admin/lender-aliases',
    component: LenderAliases,
    exact: true,
    protected: true,
    title: 'Lender Aliases',
    authorizedRoles: [Admin, Dev, QCTeamLead],
  },
  {
    path: '/admin/import-document-notes',
    exact: true,
    protected: true,
    component: ImportDocumentNotes,
    title: 'Import Document Note',
    authorizedRoles: [AccountRep, Admin, Dev],
  },
  {
    path: '/admin/new-document-type',
    exact: true,
    protected: true,
    component: NewDocumentType,
    title: 'New document type',
    authorizedRoles: [ExecutiveManagement, Dev],
  },
  {
    path: '/determination',
    exact: true,
    protected: true,
    component: DeterminationOptions,
    title: 'Audit',
    authorizedRoles: [
      BasicUser,
      AccountRep,
      CallCenterAdmin,
      Online,
      Admin,
      Dev,
      Digitals,
      DigitalsWithImportErrors,
    ],
  },
  {
    path: '/title-company-portal-links',
    exact: true,
    protected: true,
    component: TitleCompanyPortalLinks,
    title: 'Title Portal Uploads',
    authorizedRoles: [Admin, Dev, BasicUser, AccountRep, CallCenterAdmin],
  },
  {
    path: '/title-portal-links',
    exact: true,
    protected: true,
    component: TitlePortalLinks,
    title: 'Title Portal Link',
    authorizedRoles: [BasicUser, Admin, Dev, AccountRep, SalesRep],
  },
  {
    path: '/print-coversheets',
    exact: true,
    protected: true,
    component: PrintCoversheets,
    title: 'Coversheet',
    authorizedRoles: [BasicUser, AccountRep, CallCenterAdmin, Admin, Dev],
  },
  {
    path: '/investor-reports',
    exact: true,
    protected: false,
    title: 'Investor Report',
    component: InvestorReport,
  },
  {
    path: '/user-dashboard/log-event',
    exact: true,
    protected: true,
    title: 'Dashboard',
    component: UserDashboardEventLogs,
  },
  {
    path: '/powerbi',
    exact: true,
    protected: true,
    component: EmbedContainer,
    title: 'Power BI',
    authorizedRoles: [Dev, Admin, AccountRep, TitlePortalSupport],
  },
  {
    path: '/sales-reps-dashboard',
    exact: true,
    protected: true,
    component: SalesRepsDashboard,
    title: 'Sales Rep Dashboard',
    authorizedRoles: [Dev, SalesManager, SalesRep],
  },
  {
    path: '/sales-reps-landing-page',
    exact: true,
    protected: true,
    component: SalesRepLandingPage,
    title: 'Sales Rep Dashboard',
    authorizedRoles: [Dev, SalesManager, SalesRep],
  },
  {
    path: '/account-reps',
    exact: true,
    protected: true,
    title: 'Corrections',
    component: () => (
      <PrintingContextProvider>
        <AccountRepsPage />
      </PrintingContextProvider>
    ),
    authorizedRoles: [AccountRep, Admin, Dev],
  },
  {
    path: '/correction-fulfillment',
    exact: true,
    protected: true,
    component: CorrectionFulfillment,
    title: 'Correction Fulfillment',
    authorizedRoles: [BasicUser, AccountRep, CallCenterAdmin, Admin, Dev],
  },
  {
    path: '/closing-packages',
    exact: true,
    protected: true,
    component: ClosingPackages,
    title: 'Closing Packages',
    authorizedRoles: [BasicUser, AccountRep, Admin, Dev],
  },
  {
    path: '/collateral-services',
    exact: true,
    protected: true,
    component: CollateralServices,
    title: 'Collateral Services',
    authorizedRoles: [BasicUser, AccountRep, Admin, Dev],
  },
  {
    path: '/document-catch-all',
    exact: true,
    protected: true,
    component: CatchAll,
    title: 'Catch All',
    authorizedRoles: [
      Admin,
      Dev,
      BasicUser,
      DataAssociation,
      ProcessingAdmin,
      QCTeamLead,
      Digitals,
      DigitalsWithImportErrors,
      DigitalTeamLead,
      MatchAndSort,
      Online,
    ],
  },
  {
    path: '/admin/client-portal-user-management',
    exact: true,
    protected: true,
    component: ClientPortalUserManagement,
    title: 'Client Portal User Management',
    authorizedRoles: [ExecutiveManagement, Dev, Admin, ClientAdmin, AccountRep],
  },
  {
    path: '/admin/encompass-tools',
    exact: true,
    protected: true,
    component: EncompassTools,
    title: 'Encompass Tools',
    authorizedRoles: [Admin, Dev],
  },
  {
    path: '/version',
    component: AppVersion,
    exact: true,
    noLayout: true,
    authorizedRoles: [Dev],
  },
  {
    path: '/400',
    component: Page400,
    noLayout: true,
  },
  {
    path: '/403',
    component: Page403,
    noLayout: true,
  },
  {
    path: '/500',
    component: Page500,
    noLayout: true,
  },
  {
    path: '*',
    component: Page404,
    noLayout: true,
  },
];

export const routesAuthorizedRoles = routes.reduce<Map<string, string[]>>((map, route) => {
  map.set(route.path.toLowerCase(), route.authorizedRoles || []);

  return map;
}, new Map());

export default function Router() {
  const hideSidebarRoutes = routes.filter(x => x.noLayout).map(x => x.path);

  return (
    <Layout hideSidebarRoutes={hideSidebarRoutes}>
      <Switch>
        {routes.map(({ path, exact, component: Component, title, ...route }) =>
          route.protected ? (
            <ProtectedRoute
              key={path}
              path={path}
              exact={exact}
              authorizedRoles={route.authorizedRoles}
              title={title}
              component={Component}
            />
          ) : (
            <Route key={path} path={path} exact={exact} component={Component} />
          ),
        )}
      </Switch>
    </Layout>
  );
}
