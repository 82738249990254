import React from 'react';
import Datatable from '../../components/ui/DataTableV2/Datatable';
import { Column } from '../../components/ui/DataTableV2/types';

type EventData = {
  event: string;
  totalForMonth: number;
  totalForWeek: number;
  totalForToday: number;
};

const columns: Column<EventData>[] = [
  {
    id: 'event',
    label: 'Event',
    sortable: true,
  },
  {
    id: 'totalForToday',
    label: 'Total for today',
    sortable: true,
  },
  {
    id: 'totalForWeek',
    label: 'Total for week',
    sortable: true,
  },
  {
    id: 'totalForMonth',
    label: 'Total for month',
    sortable: true,
  },
];

const UserDashboardEventLogs = () => (
  <div className="m4">
    <Datatable<EventData>
      title="User dashboard"
      columns={columns}
      url="/api/user-dashboard/logged-events"
      exportUrl="/api/user-dashboard/logged-events"
      exportFileName="User dashboard.xlsx"
    />
  </div>
);

export default UserDashboardEventLogs;
