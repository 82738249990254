/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useState } from 'react';
import TextInput from '../../components/ui/Inputs/TextInput';
import StatusButton, { StatusTypes } from '../../components/ui/StatusButton';
import Panel, { PanelHeader } from '../../components/ui/Panel';
import { apiPost } from '../../adalConfig';
import { useToaster } from '../../Hooks/toasters';
import { parseRequestError } from '../../Utils';

type Props = {
  id: number;
  inboundTracking: string;
  onClose: () => void;
};

export default function EditInboundTracking({ onClose, id, inboundTracking }: Props) {
  const [newInboundTracking, setNewInboundTracking] = useState<string>(inboundTracking);

  const [status, setStatus] = useState<StatusTypes>(StatusTypes.initial);

  const { errorToaster, successToaster } = useToaster();

  const edit = async () => {
    if (newInboundTracking != inboundTracking) {
      setStatus(StatusTypes.loading);
      await apiPost(`/api/closingpackages/UpdateCollateralServiceTracking`, {
        id,
        inboundTrackingNumber: newInboundTracking,
      });
    }
  };

  const editTracking = () => {
    edit()
      .then(() => {
        setStatus(StatusTypes.success);
        successToaster('Inbound tracking number updated');
      })
      .catch(e => {
        const firstError = parseRequestError(e)[0];
        errorToaster(firstError);

        setStatus(StatusTypes.error);
      })
      .finally(() => onClose());
  };

  return (
    <Fragment>
      <div css={{ margin: 40, maxWidth: 1300 }}>
        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <Panel>
            <PanelHeader text="Update Inbound Tracking Number" />
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly',
              }}
            >
              <div className="df jcsb aife mb2 mt2">
                <div css={{ width: '100%' }}>
                  <TextInput
                    placeholder="Inbound Tracking Number"
                    name="inboundTrackingNumber"
                    value={newInboundTracking}
                    onChange={(e: React.ChangeEvent<{ value: string }>) =>
                      setNewInboundTracking(e.target.value)
                    }
                  />
                </div>
              </div>
              <div css={{ display: 'flex' }}>
                <div css={{ paddingRight: 10 }}>
                  <StatusButton onClick={onClose} text="Cancel" />
                </div>
                <StatusButton status={status} onClick={editTracking} text="Save" />
              </div>
            </div>
          </Panel>
        </div>
      </div>
    </Fragment>
  );
}
