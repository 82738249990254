/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useContext, useRef, useState } from 'react';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { roleTypes } from '../../constants';
import { AuthContext } from '../../components/AuthContext';
import { apiFetch, apiPost } from '../../adalConfig';
import { useToaster } from '../../Hooks/toasters';
import DeleteIcon from '@mui/icons-material/Delete';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { green, red } from '@mui/material/colors';
import Divider from '@mui/material/Divider';
import Datatable, { TableRef } from '../../components/ui/DataTableV2/Datatable';
import { Column } from '../../components/ui/DataTableV2/types';
import IconButton from '@mui/material/IconButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    settings: {
      display: 'grid',
      gridTemplateColumns: '2fr 1fr 1fr',
    },
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  }),
);

const DigitalDeliverySettingsListing = () => {
  const [isLoadingDocumentTypeSettings, setIsLoadingDocumentTypeSettings] = useState(false);
  const [documentTypeDigitalDeliverySettings, setDocumentTypeDigitalDeliverySettings] = useState<
    DocumentTypeDigitalDeliverySetting[] | null
  >(null);
  const [settingToDelete, setSettingToDelete] = useState<RowData | undefined>(undefined);

  const classes = useStyles();

  const { roles } = useContext(AuthContext);
  const tableRef = useRef({} as TableRef);
  const { errorToaster } = useToaster();

  const columns: Column<RowData>[] = [
    {
      id: 'id',
      label: 'Id',
      sortable: true,
      hidden: !roles.includes(roleTypes.Dev),
    },
    {
      id: 'lender',
      label: 'Lender',
      sortable: true,
      render: ({ lender }) => lender ?? '*',
    },
    {
      id: 'investor',
      label: 'Investor',
      sortable: true,
    },
    {
      id: 'shouldShipDigitally',
      label: 'Should ship digitally (master setting)',
      sortable: true,
      render: ({ shouldShipDigitally }) =>
        shouldShipDigitally ? (
          <DoneIcon style={{ color: green[500] }} />
        ) : (
          <CloseIcon style={{ color: red[800] }} />
        ),
    },
    {
      id: 'allowGinnieMaeOverride',
      label: 'Allow ginnie mae override',
      sortable: true,
      render: ({ allowGinnieMaeOverride }) =>
        allowGinnieMaeOverride ? (
          <DoneIcon style={{ color: green[500] }} />
        ) : (
          <CloseIcon style={{ color: red[800] }} />
        ),
    },
    {
      id: 'actions',
      label: 'Actions',
      sortable: false,
      render: rowData => (
        <div className="df gap-1">
          <Tooltip title="View document type overrides">
            <IconButton onClick={() => getDocumentTypeSettings(rowData.id)}>
              <MenuOpenIcon color="primary" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete setting">
            <IconButton onClick={() => setSettingToDelete(rowData)}>
              <DeleteIcon style={{ color: 'red' }} />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const getDocumentTypeSettings = async (digitalDeliverySettingId: number) => {
    setIsLoadingDocumentTypeSettings(true);
    try {
      const { data } = await apiFetch<DocumentTypeDigitalDeliverySetting[]>(
        `/api/digital-delivery-settings/document-type-settings?digitalDeliverySettingId=${digitalDeliverySettingId}`,
      );
      setDocumentTypeDigitalDeliverySettings(data);
    } catch (e) {
      let errorMessage = e.message;
      if (e.response) {
        errorMessage = e.response.data.split('\n')[0] || e.message;
      }

      errorToaster(errorMessage);
    } finally {
      setIsLoadingDocumentTypeSettings(false);
    }
  };

  const displayAllFormatDocs = (setting: DocumentTypeDigitalDeliverySetting) => {
    if (!setting.shouldShipDigitally) {
      return <div style={{ paddingLeft: '2px' }}>- -</div>;
    }

    return setting.onlyDigital ? (
      <CloseIcon style={{ color: red[800] }} />
    ) : (
      <DoneIcon style={{ color: green[500] }} />
    );
  };

  return (
    <Fragment>
      <div className="m4">
        <Datatable<RowData>
          title="Digital delivery settings"
          columns={columns}
          searchBarPlaceholder="Search by client or investor"
          url="/api/digital-delivery-settings"
          exportUrl="/api/digital-delivery-settings"
          exportFileName="Digital delivery settings.xlsx"
          ref={tableRef}
        />
      </div>

      <Dialog
        open={documentTypeDigitalDeliverySettings !== null}
        onClose={() => setDocumentTypeDigitalDeliverySettings(null)}
        maxWidth="lg"
      >
        <DialogTitle>Document Type Override Settings</DialogTitle>
        <DialogContent>
          <div>
            {isLoadingDocumentTypeSettings ? (
              <div className="center-in-parent" style={{ height: '200px', width: '300px' }}>
                <CircularProgress size="40" disableShrink />
              </div>
            ) : (
              <Fragment>
                {documentTypeDigitalDeliverySettings?.length ? (
                  <div>
                    <div className={classes.settings}>
                      <div className="bold">Document Type</div>
                      <div className="bold">Shipping Digitally</div>
                      <div className="bold">All formats (physical/digital)</div>
                    </div>

                    <Divider className={classes.divider} />

                    {documentTypeDigitalDeliverySettings.map(setting => (
                      <div className={classes.settings} key={setting.id}>
                        <div>{setting.documentType}</div>
                        <div>
                          {setting.shouldShipDigitally ? (
                            <Tooltip title="Will be digitally shipped">
                              <DoneIcon style={{ color: green[500] }} />
                            </Tooltip>
                          ) : (
                            <Tooltip title="Won't be digitally shipped">
                              <CloseIcon style={{ color: red[800] }} />
                            </Tooltip>
                          )}
                        </div>
                        <div>{displayAllFormatDocs(setting)}</div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <h3 className="df jcc">No Override Settings</h3>
                )}
              </Fragment>
            )}
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        onClose={() => setSettingToDelete(undefined)}
        aria-labelledby="delete-setting-title"
        open={settingToDelete !== undefined}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="delete-setting-title">
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <h3>
              Deleting Setting for {settingToDelete?.investor} /{' '}
              {settingToDelete?.lender ?? 'Master Setting'}
            </h3>
          </div>
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="delete-setting-dialog-description">
            <span className="db">Are you sure you want to delete this setting?</span>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setSettingToDelete(undefined)}>Cancel</Button>
          <Button
            onClick={async () => {
              await apiPost(`/api/digital-delivery-settings/delete/${settingToDelete?.id}`, {});
              const { refreshTable } = tableRef.current;
              refreshTable && refreshTable();
              setSettingToDelete(undefined);
            }}
            color="primary"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default DigitalDeliverySettingsListing;
