/** @jsx jsx */
import { jsx } from '@emotion/core';
import DeleteIcon from '@mui/icons-material/Delete';
import { useRef, useState } from 'react';
import { useToaster } from '../../Hooks/toasters';
import { apiPost } from '../../adalConfig';
import { parseRequestError } from '../../Utils';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Datatable, { TableRef } from '../../components/ui/DataTableV2/Datatable';
import { Column } from '../../components/ui/DataTableV2/types';
import Tooltip from '@mui/material/Tooltip';

type RowData = {
  id: number;
  client: string;
  email: string;
  permission: string;
  firstName: string;
  lastName: string;
  role: string;
  createdAt: string;
  isRegistered: boolean;
};

function ViewUsers() {
  const [confirmDeleteRow, setConfirmDeleteRow] = useState<RowData | null>(null);

  const tableRef = useRef({} as TableRef);
  const { successToaster, errorToaster } = useToaster();

  const columns: Column<RowData>[] = [
    {
      id: 'email',
      label: 'Email',
      sortable: true,
    },
    {
      id: 'client',
      label: 'Client',
      sortable: true,
    },
    {
      id: 'permission',
      label: 'Permission',
      sortable: true,
    },
    {
      id: 'firstName',
      label: 'First Name',
      sortable: true,
    },
    {
      id: 'lastName',
      label: 'Last Name',
      sortable: true,
    },
    {
      id: 'role',
      label: 'Role',
      sortable: true,
    },
    {
      id: 'createdAt',
      label: 'Date Created',
      sortable: true,
      render: ({ createdAt }) => new Date(createdAt).toLocaleDateString(),
    },
    {
      id: 'actions',
      label: 'Actions',
      render: rowData =>
        rowData.isRegistered ? (
          <Tooltip title="Delete user">
            <DeleteIcon style={{ color: 'red' }} onClick={() => setConfirmDeleteRow(rowData)} />
          </Tooltip>
        ) : null,
    },
  ];

  const deleteUser = async () => {
    try {
      await apiPost('/api/client-portal-user-management/delete-user', { id: confirmDeleteRow?.id });
      successToaster('Successfully deleted user');
      const { refreshTable } = tableRef.current;
      refreshTable && refreshTable();
      setConfirmDeleteRow(null);
    } catch (e) {
      const firstError = parseRequestError(e)[0];
      errorToaster(firstError);
    }
  };

  return (
    <div style={{ marginTop: 16, marginLeft: '-12px' }}>
      <Dialog open={confirmDeleteRow !== null} onClose={() => setConfirmDeleteRow(null)}>
        <DialogTitle>Delete User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please confirm you want to delete user {confirmDeleteRow?.email}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDeleteRow(null)} autoFocus>
            Cancel
          </Button>
          <Button onClick={deleteUser}>Delete</Button>
        </DialogActions>
      </Dialog>

      <Datatable<RowData>
        title="Users"
        columns={columns}
        searchBarPlaceholder="Search by user first/last name, client, or email"
        url="/api/client-portal-user-management/get-users"
        exportUrl="/api/client-portal-user-management/get-users"
        exportFileName="Users.xlsx"
        sortConfig={{ field: 'createdAt', direction: 'desc' }}
        ref={tableRef}
      />
    </div>
  );
}

export default ViewUsers;
