/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { AlertProps, Dialog, DialogTitle, Link, Modal, Snackbar, Tooltip } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import MuiAlert from '@mui/material/Alert';
import CommentIcon from '@mui/icons-material/Comment';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import PostAddIcon from '@mui/icons-material/PostAdd';
import {
  Document,
  DocumentEmailAttachment,
  DocumentType,
  FlagInformation,
} from '../globalTypes/objects';
import CureDocument from './CureDocument';
import { formatPhoneWithParenthesis } from '../Utils';
import { apiFetch, apiPost } from '../adalConfig';
import AddNoteInput from './Notes/AddNoteInput';
import { useToaster } from '../Hooks/toasters';
import EmailAttachments from './Documents/EmailAttachments';
import { FlagIcon } from './ui/icons';
import FlagDocumentModal from '../pages/FlagDocuments/FlagDocumentModal';
import Datatable, { SelectionAction, TableRef } from './ui/DataTableV2/Datatable';
import { Column, Filter } from './ui/DataTableV2/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'inline-flex',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      margin: '16px 3px 0px',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
    paper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '800px',
      maxHeight: '90%',
      overflow: 'auto',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }),
);

const detailPanelStyles = props => css`
  padding: 16px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr repeat(4, 2fr) 5fr;
  grid-gap: 32px;
  background-color: ${props.correctionId ? '#EDF7ED' : '#FFF'};
  position: relative;
  ::before {
    content: '';
    position: absolute;
    top: -1px;
    width: 100%;
    border-top: 1px solid ${props.correctionId ? '#EDF7ED' : '#FFF'};
    z-index: 5;
  }
`;

const subSectionStyles = css`
  background-color: rgb(225, 225, 225, 0.3);
  border-radius: 8px;
  padding: 8px;
`;

const emailStyles = css`
  color: #0828cc;
  :hover {
    text-decoration: underline;
  }
`;

const attachmentButtonStyles = css`
  color: #0828cc;
  :hover {
    text-decoration: underline;
  }
`;

interface RowData {
  documentId: number;
  documentType: DocumentType;
  documentTypeId: number;
  isInvestorFailure: boolean;
  hardCopy: boolean;
  auditNote?: string;
  failedAuditReasons?: string;
  failedVerificationNote?: string;
  loanId: number;
  loanNumber: string;
  titleNumber?: string;
  borrower: string;
  investor: string;
  client: string;
  clientId?: number;
  propertyAddress: string;
  city: string;
  state: string;
  zip: string;
  county?: string;
  dateFunded: string;
  dateFailedAudit?: string;
  dateFailedVerification?: string;
  loanLevelPhone?: string;
  loanLevelEmail?: string;
  titleCompany?: string;
  titleCompanyPhone?: string;
  titleCompanyEmail?: string;
  titleCompanyId?: number;
  documentSender?: string;
  correctionId?: number;
  lastNoteDate?: string;
  documentNotes: DocumentNote[];
}

export interface DocumentNote {
  id: number;
  noteText: string;
  createdAt: Date;
  createdBy: string;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const filters = [
  {
    filterType: 'radio',
    id: 'clients',
    label: 'Pods',
    options: [
      {
        label: 'My Clients',
        value: 0,
        active: true,
      },
      {
        label: 'AMR Clients',
        value: 1,
        active: false,
      },
      {
        label: 'MyTeam',
        value: 2,
        active: false,
      },
      {
        label: 'OurTeam',
        value: 3,
        active: false,
      },
      {
        label: 'HelloTeam',
        value: 4,
        active: false,
      },
      {
        label: 'YourTeam',
        value: 5,
        active: false,
      },
      {
        label: 'All Clients',
        value: 6,
        active: false,
      },
    ],
  },
  {
    filterType: 'radio',
    id: 'outstandingDocuments',
    label: 'Outstanding documents',
    options: [
      {
        label: 'All documents',
        value: 0,
        active: true,
      },
      {
        label: 'Outstanding docs only',
        value: 1,
        active: false,
      },
    ],
  },
  {
    filterType: 'radio',
    id: 'mayHaveCure',
    label: 'May have cure',
    options: [
      {
        label: 'May have cure',
        value: 0,
        active: false,
      },
      {
        label: 'All documents',
        value: 1,
        active: true,
      },
    ],
  },
  {
    filterType: 'radio',
    id: 'failureSensitivity',
    label: 'Failure sensitivity',
    options: [
      {
        label: 'All documents',
        value: 0,
        active: true,
      },
      {
        label: 'Tier 1',
        value: 1,
        active: false,
      },
      {
        label: 'Tier 2',
        value: 2,
        active: false,
      },
    ],
  },
  {
    filterType: 'checkbox',
    id: 'documentStatus',
    label: 'Document status',
    options: [
      {
        label: 'Internal failure',
        value: '0',
        active: true,
      },
      {
        label: 'Investor failure',
        value: '1',
        active: true,
      },
      {
        label: 'Corrected',
        value: '2',
        active: false,
      },
    ],
  },
  {
    filterType: 'dropdown',
    id: 'clientSearch',
    label: 'Clients',
    multiple: true,
    optionsUrl: '/api/clients/filter-dropdown',
    options: [
      {
        label: '',
        value: null,
        active: false,
      },
    ],
  },
  {
    filterType: 'dropdown',
    id: 'investorSearch',
    label: 'Investors',
    multiple: true,
    optionsUrl: '/api/investors/filter-dropdown',
    options: [
      {
        label: '',
        value: null,
        active: false,
      },
    ],
  },
  {
    filterType: 'dropdown',
    id: 'titleCompanies',
    label: 'Title Companies',
    multiple: true,
    optionsUrl: '/api/titleCompanies/filter-dropdown',
    options: [
      {
        label: '',
        value: null,
        active: false,
      },
    ],
  },
] as Filter<RowData>[];

export default function AccountRepsPage() {
  const [currentlyCuring, setCurrentlyCuring] = useState<false | RowData>(false);
  const [curingDocument, setCuringDocument] = useState<Document>();
  const [cureMessage, setCureMessage] = useState<null | string>(null);

  const [newNoteDocuments, setNewNoteDocuments] = useState<RowData[]>([]);
  const [newNoteOpen, setNewNoteOpen] = useState(false);

  const [flagDocumentInformation, setFlagDocumentInformation] = useState<FlagInformation>();
  const [flagDocumentOpen, setFlagDocumentOpen] = useState(false);

  const [attachmentModalOpen, setAttachmentModalOpen] = useState<false | RowData>(false);
  const [attachments, setAttachments] = useState<DocumentEmailAttachment[]>([]);

  const { successToaster, errorToaster } = useToaster();

  const classes = useStyles();
  const tableRef = useRef({} as TableRef);

  const onDocumentCured = (message: string) => {
    setCurrentlyCuring(false);
    setCureMessage(message);
    if (!message.startsWith('ERROR')) {
      const { refreshTable } = tableRef.current;
      refreshTable && refreshTable();
    }
  };

  useEffect(() => {
    if (!(currentlyCuring && currentlyCuring.documentId)) {
      return;
    }

    apiFetch<Document>(`/Api/Documents/GetDocument?docId=${currentlyCuring.documentId}`).then(
      ({ data }) => setCuringDocument(data),
    );
  }, [currentlyCuring]);

  useEffect(() => {
    if (!(attachmentModalOpen && attachmentModalOpen.documentId)) {
      return;
    }

    apiFetch<DocumentEmailAttachment[]>(
      `/Api/DocumentEmail/GetRelatedAttachments/${attachmentModalOpen.documentId}`,
    ).then(({ data }) => setAttachments(data));
  }, [attachmentModalOpen]);

  const saveNote = async (note: string) => {
    if (newNoteDocuments.length > 0) {
      const postData = {
        noteText: note,
        documentIds: newNoteDocuments.map(row => row.documentId),
      };

      try {
        await apiPost('/api/Document-Notes/add-bulk-note', postData);
        successToaster('Successfully added note(s).');
        const { refreshTable } = tableRef.current;
        refreshTable && refreshTable();
      } catch (e) {
        if (e.response) {
          const errorMessage = e.response.data.split('\n')[0];
          errorToaster(errorMessage || e.message);
        } else {
          errorToaster(e.message);
        }
      }
      setNewNoteDocuments([]);
      setNewNoteOpen(false);
    }
  };

  const columns: Column<Omit<RowData, 'documentNotes'>>[] = [
    {
      id: 'client',
      label: 'Client',
      sortable: false,
      render: ({ client, clientId }) => {
        return (
          <div
            css={{ cursor: 'pointer' }}
            onClick={() => {
              const { updateFilters } = tableRef.current;
              if (!updateFilters || !clientId) {
                return;
              }
              updateFilters({
                clientSearch: { filterType: 'dropdown', values: [clientId] },
              });
            }}
          >
            {client}
          </div>
        );
      },
    },
    {
      id: 'titleCompany',
      label: 'Title Company',
      sortable: false,
      render: ({ titleCompany, titleCompanyId }) => (
        <div
          css={{ cursor: 'pointer' }}
          onClick={() => {
            const { updateFilters } = tableRef.current;
            if (!updateFilters || !titleCompanyId) {
              return;
            }
            updateFilters({
              titleCompanies: { filterType: 'dropdown', values: [titleCompanyId] },
            });
          }}
        >
          {titleCompany}
        </div>
      ),
    },
    {
      id: 'borrower',
      label: 'Borrower',
      sortable: false,
    },
    {
      id: 'investor',
      label: 'Investor',
      sortable: false,
    },
    {
      id: 'documentType',
      label: 'Doc Type',
      sortable: false,
    },
    {
      id: 'dateFunded',
      label: 'Date Funded',
      sortable: true,
      render: ({ dateFunded }) =>
        dateFunded !== null ? new Date(dateFunded).toLocaleDateString() : null,
    },
    {
      id: 'dateFailedVerification',
      label: 'Date Failed',
      sortable: false,
      render: ({ dateFailedAudit, dateFailedVerification }) => (
        <Fragment>
          {!!dateFailedAudit && <div>{new Date(dateFailedAudit).toLocaleDateString()}</div>}
          {!!dateFailedVerification && (
            <div>{new Date(dateFailedVerification).toLocaleDateString()}</div>
          )}
        </Fragment>
      ),
    },
    {
      id: 'hardCopy',
      label: 'Format',
      sortable: false,
      render: ({ hardCopy }) => (hardCopy ? 'Physical' : 'Digital'),
    },
    {
      id: 'county',
      label: 'County',
      sortable: false,
    },
    {
      id: 'auditNote',
      label: 'Failed Audit Reasons and Note',
      sortable: false,
      render: ({ auditNote, failedAuditReasons, isInvestorFailure }) => (
        <Fragment>
          {isInvestorFailure && <div>Investor Fail -</div>}
          <div>{failedAuditReasons?.replace(/_/g, ' ').toLowerCase()}</div>
          <div>{auditNote}</div>
        </Fragment>
      ),
    },
    {
      id: 'failedVerificationNote',
      label: 'Failed Verification Fields/ Notes',
      sortable: false,
    },
    {
      id: 'lastNoteDate',
      label: 'Last Action Date',
      sortable: false,
      render: ({ lastNoteDate }) =>
        lastNoteDate ? <div>{new Date(lastNoteDate).toLocaleDateString()}</div> : <Fragment />,
    },
  ];

  const detailsDisplay = (rowData: RowData) => (
    <div css={detailPanelStyles(rowData)}>
      <div>
        <div css={[subSectionStyles, { display: 'inline-block' }]}>
          {!rowData.correctionId && (rowData.dateFailedVerification || rowData.dateFailedAudit) && (
            <span css={{ padding: 4, paddingRight: 8 }}>
              <Tooltip title="Resolve">
                <PlaylistAddCheckIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setCurrentlyCuring(rowData);
                  }}
                />
              </Tooltip>
            </span>
          )}

          <span css={{ padding: 4 }}>
            <Tooltip title="Add a Note">
              <CommentIcon
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setNewNoteOpen(true);
                  setNewNoteDocuments([rowData]);
                }}
              />
            </Tooltip>
          </span>

          <span css={{ padding: 4 }}>
            <Tooltip title="Flag document">
              <Fragment>
                <FlagIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setFlagDocumentOpen(true);
                    setFlagDocumentInformation({
                      clientId: rowData?.clientId,
                      documentType: rowData?.documentTypeId,
                      loanNumber: rowData?.loanNumber,
                      address: rowData?.propertyAddress,
                      borrower: rowData?.borrower,
                    } as FlagInformation);
                  }}
                />
              </Fragment>
            </Tooltip>
          </span>
        </div>
      </div>

      <div>
        <p>
          <span>Barcode ID: </span>
          <Link target="_blank" href={`/documents/${rowData.documentId}`}>
            {rowData.documentId}
          </Link>
        </p>
        {rowData.loanId && rowData.loanId > 0 && (
          <Fragment>
            <p>
              <span>Loan ID: </span>
              <Link target="_blank" href={`/loans/${rowData.loanId}`}>
                {rowData.loanId}
              </Link>
            </p>
            <p>
              <span>Loan Number: </span>
              <Link target="_blank" href={`/loans/${rowData.loanId}`}>
                {rowData.loanNumber}
              </Link>
            </p>
          </Fragment>
        )}
      </div>

      <div>
        {(rowData.titleCompanyPhone || rowData.titleCompanyEmail) && (
          <Fragment>
            <p>Global Contact Info:</p>
            <a href={`tel:${rowData.titleCompanyPhone}`}>
              <p>
                {rowData.titleCompanyPhone && formatPhoneWithParenthesis(rowData.titleCompanyPhone)}
              </p>
            </a>
            <a href={`mailto:${rowData.titleCompanyEmail}`}>
              <p css={emailStyles}>{rowData.titleCompanyEmail}</p>
            </a>
          </Fragment>
        )}
      </div>
      <div>
        {(rowData.loanLevelPhone || rowData.loanLevelEmail) && (
          <Fragment>
            <p>Loan Level Contact:</p>
            <a href={`tel:${rowData.loanLevelPhone}`}>
              <p>{rowData.loanLevelPhone && formatPhoneWithParenthesis(rowData.loanLevelPhone)}</p>
            </a>
            <a href={`mailto:${rowData.loanLevelEmail}`}>
              <p css={emailStyles}>{rowData.loanLevelEmail}</p>
            </a>
          </Fragment>
        )}
      </div>
      <div>
        {rowData.documentSender && (
          <Fragment>
            <p>Document Sender:</p>
            <a href={`mailto:${rowData.documentSender}`}>
              <p css={emailStyles}>{rowData.documentSender}</p>
            </a>
            <button
              css={attachmentButtonStyles}
              onClick={() => {
                setAttachmentModalOpen(rowData);
              }}
            >
              View Attachments
            </button>
          </Fragment>
        )}
      </div>

      {rowData.documentNotes && (
        <div>
          <b css={{ padding: '0px 8px 8px' }}>NOTES:</b>
          <NotesDisplay notes={rowData.documentNotes} />
        </div>
      )}
    </div>
  );

  const actions = [
    {
      key: 'add-note',
      tooltip: 'Add note for all selected documents',
      icon: () => <PostAddIcon color="primary" />,
      onClick: () => setNewNoteOpen(!newNoteOpen),
    } as SelectionAction,
  ];

  return (
    <Fragment>
      <div className="m4">
        <Datatable<Omit<RowData, 'documentNotes'>>
          title="Correction pipeline documents"
          columns={columns}
          filters={filters}
          url="/Api/AccountReps/Documents"
          exportUrl="/Api/AccountReps/Documents"
          exportFileName="Correction Pipeline Documents.xlsx"
          searchBarPlaceholder="Search by Barcode, loan id, loan #, or borrower"
          detailsPanel={detailsDisplay}
          sortConfig={{ field: 'dateFunded', direction: 'asc' }}
          rowStyle={row => ({
            backgroundColor: row.correctionId ? '#EDF7ED' : '#FFF' /* #c8f7c8 */,
          })}
          rowSelectionActions={actions}
          onSelectionChange={rows => setNewNoteDocuments(rows as RowData[])}
          ref={tableRef}
        />
      </div>

      {/* RESOLVE DOCUMENT DIALOG */}
      <Dialog
        maxWidth="sm"
        fullWidth
        open={currentlyCuring !== false}
        onClose={() => {
          setCurrentlyCuring(false);
          setCuringDocument(undefined);
        }}
      >
        <DialogTitle>
          Resolve Document {curingDocument?.id} {curingDocument?.hardCopy ? 'PHYSICAL' : 'DIGITAL'}
          <div css={{ fontWeight: 400, fontSize: 15 }}>
            {currentlyCuring && currentlyCuring.loanId !== 0 && (
              <div>Loan ID: {currentlyCuring.loanId}</div>
            )}
            {currentlyCuring && currentlyCuring.borrower && (
              <div>Borrower: {currentlyCuring.borrower}</div>
            )}
            {currentlyCuring && currentlyCuring.documentType && (
              <div>Document Type: {currentlyCuring.documentType}</div>
            )}
          </div>
        </DialogTitle>
        {curingDocument && <CureDocument document={curingDocument} onCure={onDocumentCured} />}
      </Dialog>

      {/* ADD NEW NOTE MODAL */}
      <Modal
        open={newNoteOpen}
        onClose={() => {
          setNewNoteOpen(false);
          setNewNoteDocuments([]);
        }}
      >
        <div className={classes.paper}>
          <AddNoteInput
            noteType={
              newNoteDocuments.length === 1
                ? `${newNoteDocuments[0].documentType} - Document ${newNoteDocuments[0].documentId}`
                : 'Bulk Document'
            }
            addNote={saveNote}
          />
        </div>
      </Modal>

      {/* SUCCESS MESSAGES */}
      <Snackbar
        open={cureMessage !== null}
        autoHideDuration={6000}
        onClose={() => setCureMessage(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          severity={!cureMessage?.startsWith('ERROR') ? 'success' : 'error'}
          onClose={() => setCureMessage(null)}
        >
          {cureMessage === 'DIGITAL' && 'Success! Doc is now available for shipping.'}
          {cureMessage === 'PHYSICAL' && 'Success! Please print this document.'}
          {cureMessage === 'REPLACEMENT' && 'Success! Document has been replaced.'}
          {cureMessage === 'NOTE' && 'Success! Doc marked as passed.'}
          {cureMessage?.startsWith('ERROR') && cureMessage}
        </Alert>
      </Snackbar>

      <Modal open={attachmentModalOpen !== false} onClose={() => setAttachmentModalOpen(false)}>
        <div className={classes.paper}>
          <EmailAttachments attachments={attachments} />
        </div>
      </Modal>

      <Modal open={flagDocumentOpen} onClose={() => setFlagDocumentOpen(false)}>
        <Fragment>
          <FlagDocumentModal
            setModalOpen={setFlagDocumentOpen}
            flagInformation={flagDocumentInformation}
          />
        </Fragment>
      </Modal>
    </Fragment>
  );
}

export const NotesDisplay = ({ notes }: { notes: DocumentNote[] }) => (
  <div css={[subSectionStyles, { padding: 16, maxHeight: 100, overflowY: 'scroll' }]}>
    {notes
      .sort((a, b) => Date.parse(a.createdAt.toString()) - Date.parse(b.createdAt.toString()))
      .map((documentNote: DocumentNote) => (
        <div key={documentNote.id}>
          <div
            css={{
              display: 'grid',
              gridTemplateColumns: '100px 100px 1fr',
            }}
          >
            <b>{new Date(documentNote.createdAt).toLocaleDateString()}</b>
            <b>{documentNote.createdBy}</b>
            <div>
              <p style={{ whiteSpace: 'pre-wrap' }}>{documentNote.noteText}</p>
            </div>
          </div>
        </div>
      ))}
  </div>
);
