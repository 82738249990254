/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useContext } from 'react';
import { AuthContext } from '../../components/AuthContext';
import { roleTypes } from '../../constants';
import Datatable from '../../components/ui/DataTableV2/Datatable';
import { Column, Filter } from '../../components/ui/DataTableV2/types';

type RowData = {
  logId: number;
  client: string;
  email: string;
  role: string;
  pageRoute: string;
  createdAt: string;
};

const filters: Filter<RowData>[] = [
  {
    filterType: 'dateRange',
    id: 'logDate',
    label: 'Log date',
    options: [
      {
        label: '',
        value: null,
        active: false,
      },
    ],
  },
  {
    filterType: 'dropdown',
    id: 'clients',
    label: 'Clients',
    multiple: true,
    optionsUrl: '/api/clients/filter-dropdown',
    options: [
      {
        label: '',
        value: null,
        active: false,
      },
    ],
  },
  {
    filterType: 'dropdown',
    id: 'emails',
    label: 'User',
    multiple: true,
    optionsUrl: '/api/client-portal-user-management/filter-dropdown',
    options: [
      {
        label: '',
        value: null,
        active: false,
      },
    ],
  },
];

const Analytics = () => {
  const { roles } = useContext(AuthContext);

  const columns: Column<RowData>[] = [
    {
      id: 'logId',
      label: 'Log Id',
      sortable: true,
      hidden: !roles.includes(roleTypes.Dev),
    },
    {
      id: 'client',
      label: 'Client',
      sortable: true,
    },
    {
      id: 'email',
      label: 'Email',
      sortable: true,
    },
    {
      id: 'role',
      label: 'Role',
      sortable: true,
    },
    {
      id: 'pageRoute',
      label: 'Page',
      sortable: true,
    },
    {
      id: 'createdAt',
      label: 'Timestamp (EST)',
      sortable: true,
      render: ({ createdAt }) => <span>{new Date(createdAt).toLocaleString()}</span>,
    },
  ];

  return (
    <Datatable<RowData>
      title="Client portal analytics"
      columns={columns}
      filters={filters}
      searchBarPlaceholder="Search by page route"
      url="/api/client-portal-user-management/analytics"
      exportUrl="/api/client-portal-user-management/analytics"
      exportFileName="Client portal analytics.xlsx"
      sortConfig={{ field: 'createdAt', direction: 'desc' }}
    />
  );
};

export default Analytics;
