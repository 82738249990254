/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '../ui/Tabs/MaterialTabPanel';
import AddDomainMapping from './AddDomainMapping';
import ViewDomainMappings from './ViewDomainMappings';

type Props = {
  isOpen: boolean;
  close: () => void;
};

const TitleDomainMappingDialog = ({ isOpen, close }: Props) => {
  const [tab, setTab] = useState(0);

  return (
    <Dialog open={isOpen} onClose={close} maxWidth="lg" fullWidth>
      <DialogTitle>Domain Mappings</DialogTitle>
      <DialogContent>
        <Tabs
          indicatorColor="primary"
          value={tab}
          textColor="primary"
          onChange={(e, newValue: number) => setTab(newValue)}
        >
          {['Add Mapping', 'View Mappings'].map((tabLabel, i) => (
            <Tab key={tabLabel} label={tabLabel} id={`tabpanel-${i}`} />
          ))}
        </Tabs>
        <TabPanel value={tab} index={0}>
          <AddDomainMapping isOpen={isOpen} close={close} />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <ViewDomainMappings />
        </TabPanel>
      </DialogContent>
    </Dialog>
  );
};

export default TitleDomainMappingDialog;
