/** @jsx jsx */
import { jsx } from '@emotion/core';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { apiFetch } from '../../adalConfig';
import CircularProgress from '@mui/material/CircularProgress';
import { useMemo } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';

type Props = {
  loanId: number;
  open: boolean;
  handleCloseDialog: () => void;
};

type Log = {
  id: string;
  timestamp: string;
  user: string;
  documentType: string;
  tableChange: Change[];
};

type Change = {
  field: string;
  oldValue: string;
  newValue: string;
};

type FlatLog = {
  id: string;
  timestamp: string;
  user: string;
  documentType: string;
  field: string;
  oldValue: string;
  newValue: string;
};

const getChangeLog = async ({ queryKey }: QueryFunctionContext<(string | number)[]>) => {
  const { data } = await apiFetch('/api/loans/loan-document-change-logs', {
    params: { loanId: queryKey[1] },
  });

  return data as Log[];
};

const fieldValueDisplay = (value: string) => {
  switch (value) {
    case 'IsRequired':
      return 'Required status';
    case 'InvestorId':
      return 'Investor Id';
    case 'ExcludeOcr':
      return 'Exclude Ocr';
    case 'MatchOnUpload':
      return 'Match on upload';
    case 'PassAuditOnUpload':
      return 'Pass audit on upload';
    default:
      return value;
  }
};

const logValueDisplay = (field: string, value: string) => {
  if (field === 'IsRequired') {
    return value === 'True' ? 'Required' : value === 'False' ? 'Not Required' : value;
  }

  if (field === 'InvestorId') {
    return (
      value && (
        <Tooltip title="View investor">
          <Link target="_blank" href={`/investors/${value}`}>
            {value}
          </Link>
        </Tooltip>
      )
    );
  }

  if (field === 'ExcludeOcr') {
    return value === 'True' ? 'Exclude Ocr' : value === 'False' ? 'Ocr' : value;
  }

  if (field === 'MatchOnUpload') {
    return value === 'True' ? 'Match' : value === 'False' ? 'No match' : value;
  }

  if (field === 'PassAuditOnUpload') {
    return value === 'True' ? 'Auto pass' : value === 'False' ? 'No auto pass' : value;
  }

  return value;
};

const LoanDocumentChangeLogDialog = ({ loanId, open, handleCloseDialog }: Props) => {
  const { data: changeLogs, isFetching: isFetchingLogs } = useQuery(
    ['loan-document-change-log', loanId],
    getChangeLog,
    {
      refetchOnWindowFocus: false,
    },
  );

  const groupedChangeLogs = useMemo(() => {
    if (changeLogs === undefined) {
      return [];
    }

    const flattenedChangeLogs = changeLogs.flatMap(log => {
      return log.tableChange.map(change => {
        return {
          ...change,
          id: log.id,
          timestamp: log.timestamp,
          user: log.user,
          documentType: log.documentType,
        } as FlatLog;
      });
    });

    const groupedByDocumentType = Object.groupBy(flattenedChangeLogs, l => l.documentType);
    return Object.entries(groupedByDocumentType).map(([documentType, logs]) => ({
      documentType,
      // Sort descending
      documentLogs: logs!.toSorted((a, b) =>
        new Date(a.timestamp) > new Date(b.timestamp) ? -1 : 1,
      ),
    }));
  }, [changeLogs]);

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleCloseDialog}>
      <DialogTitle>Change logs</DialogTitle>
      <DialogContent>
        {isFetchingLogs ? (
          <div className="center-in-parent" style={{ height: '40px', width: '80px' }}>
            <CircularProgress size="20" disableShrink />
          </div>
        ) : groupedChangeLogs.length > 0 ? (
          <DialogContentText>
            {groupedChangeLogs.map(({ documentType, documentLogs }) => (
              <Box sx={{ width: '100%' }}>
                <Paper sx={{ width: '100%', mb: 2 }} key={documentType}>
                  <Typography className="tac py1" variant="h6" component="div">
                    <span style={{ color: 'blue', fontStyle: 'italic' }}>{documentType}</span>{' '}
                    changes
                  </Typography>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Affected Field</TableCell>
                          <TableCell>Original Value</TableCell>
                          <TableCell>New Value</TableCell>
                          <TableCell>User</TableCell>
                          <TableCell>Timestamp (local)</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {documentLogs.map(log => (
                          <TableRow
                            key={log.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell>{fieldValueDisplay(log.field)}</TableCell>
                            <TableCell>{logValueDisplay(log.field, log.oldValue)}</TableCell>
                            <TableCell>{logValueDisplay(log.field, log.newValue)}</TableCell>
                            <TableCell>{log.user}</TableCell>
                            <TableCell>{new Date(log.timestamp).toLocaleString()}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Box>
            ))}
          </DialogContentText>
        ) : (
          <div className="center-in-parent">
            <div>No logs for this loan</div>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default LoanDocumentChangeLogDialog;
