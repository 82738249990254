/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useMutation } from '@tanstack/react-query';
import { useDropzone } from 'react-dropzone';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Document, DocumentType } from '../../globalTypes/objects';
import { convertToBase64 } from '../../Utils';
import { apiPost } from '../../adalConfig';
import { LoadingButton } from '@mui/lab';
import { useErrorToaster, useSuccessToaster } from '../../Hooks/toasters';

interface DocumentForm {
  documentType: DocumentType | undefined;
  loanId: number;
  clientId: number;
  fileContents: Promise<string>;
  filename: string | undefined;
  uploadedToLoan: boolean;
}

async function uploadDocument(form: DocumentForm) {
  const fileContents = await form.fileContents;
  await apiPost<Document>(`/Api/Documents/CreateSoftCopy`, { ...form, fileContents });
  return `uploaded ${form.filename} successfully`;
}

export function UploadCollateralDocumentForm({
  documentType,
  loanId,
  clientId,
  onUploadSuccess,
}: {
  documentType: DocumentType;
  loanId: number;
  clientId: number;
  onUploadSuccess: () => void;
}) {
  const errorToaster = useErrorToaster();
  const successToaster = useSuccessToaster();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: 'application/pdf',
    noDrag: true,
  });

  const { mutate: uploadDocumentMutation, isLoading } = useMutation({
    mutationFn: uploadDocument,
    onSuccess: _data => {
      successToaster('File created');
      onUploadSuccess();
    },
    onError: error => {
      errorToaster(error);
    },
  });

  const file = acceptedFiles.length ? acceptedFiles[0] : null;
  const filename = file?.name;
  const fileContents = file ? convertToBase64(file) : null;

  const formData: DocumentForm | null =
    file && filename && fileContents
      ? {
          filename,
          uploadedToLoan: true,
          clientId,
          fileContents,
          documentType,
          loanId,
        }
      : null;

  return (
    <div>
      {acceptedFiles.length && formData ? (
        <div>
          <div>{acceptedFiles.map(file => file.name)}</div>
          <LoadingButton
            loading={isLoading}
            variant="text"
            size="small"
            type="button"
            css={{
              minWidth: '100px',
              height: '30px',
              marginTop: '5px',
              color: 'white',
              backgroundColor: 'blue',
              borderRadius: '5px',
            }}
            onClick={() => uploadDocumentMutation(formData)}
          >
            Upload
          </LoadingButton>
        </div>
      ) : (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <FileUploadOutlinedIcon />
        </div>
      )}
    </div>
  );
}
