/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Dispatch, SetStateAction } from 'react';
import { Option } from '../../../ui/Downshift/types';
import { ReportType } from './Report';
import Datatable from '../../../ui/DataTableV2/Datatable';
import { Column } from '../../../ui/DataTableV2/types';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

export type RowData = {
  client: string;
  reportType: string;
  count: number;
  total: number;
  invoiceId: number;
};

type Props = {
  datatableData: RowData[];
  setDatatableData: Dispatch<SetStateAction<RowData[]>>;
  reportType: ReportType;
  client?: Option;
  month: string;
};

const columns = (reportType: ReportType) =>
  [
    {
      id: 'client',
      label: 'Client',
      hidden: reportType === ReportType.AllClients,
    },
    {
      id: 'reportType',
      label: 'Report Type',
    },
    {
      id: 'count',
      label: 'Count',
      render: ({ count }) => count.toLocaleString(),
    },
    {
      id: 'total',
      label: 'Total Charge/Credit',
      render: ({ total }) => total.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
    },
    {
      id: 'invoiceId',
      label: 'Invoice #',
      hidden: reportType === ReportType.AllClients,
    },
  ] as Column<RowData>[];

const reportTitle = (month: string, clientName: string) => {
  let title = `${month} Revenue Breakdown Report`;

  if (clientName) {
    title += ` - ${clientName}`;
  }

  return title;
};

const ReportModal = ({ datatableData, setDatatableData, reportType, client, month }: Props) => {
  const title = reportTitle(month, client?.label ?? '');

  return (
    <Dialog
      open={!!datatableData.length}
      onClose={() => setDatatableData([])}
      maxWidth="lg"
      fullWidth
    >
      <DialogContent>
        <Datatable<RowData>
          title={title}
          columns={columns(reportType)}
          data={datatableData}
          exportFileName={`${title}.csv`}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ReportModal;
