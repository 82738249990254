/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import Datatable, { TableRef } from '../../components/ui/DataTableV2/Datatable';
import { Column } from '../../components/ui/DataTableV2/types';

export type RowData = {
  client: string;
  amountPerMonth: number;
  amountPerQuarter: number;
};

const columns: Column<RowData>[] = [
  {
    id: 'client',
    label: 'Client',
    sortable: true,
  },
  {
    id: 'amountPerMonth',
    label: 'Amount per month',
    sortable: true,
  },
  {
    id: 'amountPerQuarter',
    label: 'Amount per quarter (billable)',
    sortable: true,
  },
];

const LoansReceived = () => {
  const [clientLoansDate, setClientLoanDate] = useState<Date | null>(null);

  const tableRef = useRef({} as TableRef);
  useEffect(() => {
    if (clientLoansDate === null) {
      return;
    }

    const { refreshTable } = tableRef.current;
    refreshTable && refreshTable();
  }, [clientLoansDate]);

  return (
    <div className="m4">
      <b>Loan Volume Counts</b>
      <div className="mb3">
        <DatePicker
          selected={clientLoansDate}
          onChange={dateTime => setClientLoanDate(dateTime)}
          placeholderText="Click to select a date"
          dateFormat="MMMM yyyy"
          showMonthYearPicker
        />
      </div>
      {clientLoansDate !== null && (
        <Datatable<RowData>
          title="Loans received - by client"
          columns={columns}
          url={`/api/reports/loans-received-count?monthAndYear=${
            clientLoansDate.toISOString().split('T')[0]
          }`}
          exportUrl={`/api/reports/loans-received-count?monthAndYear=${
            clientLoansDate.toISOString().split('T')[0]
          }`}
          exportFileName="Loans received - by client.xlsx"
          ref={tableRef}
        />
      )}
    </div>
  );
};

export default LoansReceived;
