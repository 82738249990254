/** @jsx jsx */
import { jsx } from '@emotion/core';
import { ReactNode, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import VirtualListComponent from '../ui/DataTableV2/VirtualListComponent';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { DropdownListItem } from '../CallCenter/types';
import { useToaster } from '../../Hooks/toasters';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { parseRequestError } from '../../Utils';
import { apiFetch, apiPost } from '../../adalConfig';

type TitleCompanyAssociation = {
  associatedId: number;
  emailDomain: string;
  name: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  fax: string;
};

type PostData = {
  titleCompanyId: number;
  titleAssociation: Omit<TitleCompanyAssociation, 'associatedId'>;
};

type Props = {
  isOpen: boolean;
  close: () => void;
};

export const getTitleCompanyDropdownData = async () => {
  const { data } = await apiFetch<DropdownListItem[]>(
    '/api/titleCompanies/title-company-dropdown-data',
  );
  return data;
};

// Regex is meant to remove all but the domain from the email if it exists
const domainRegex = /(?:.*@)?(.*)/g;

const canSubmit = (titleCompanyId: number | undefined, emailDomain: string) => {
  if (!titleCompanyId || !emailDomain.length) {
    return false;
  }

  return emailDomain.trim().replace(domainRegex, '$1') !== '';
};

const postMapping = async ({ titleCompanyId, titleAssociation }: PostData) => {
  if (!canSubmit(titleCompanyId, titleAssociation.emailDomain)) {
    return;
  }

  const postData: TitleCompanyAssociation = {
    associatedId: titleCompanyId,
    emailDomain: titleAssociation.emailDomain.replace(domainRegex, '$1').trim(),
    name: titleAssociation.name.trim(),
    address: titleAssociation.address.trim(),
    city: titleAssociation.city.trim(),
    state: titleAssociation.state.trim(),
    zip: titleAssociation.zip.trim(),
    phone: titleAssociation.phone.trim(),
    fax: titleAssociation.fax.trim(),
  };

  await apiPost('/api/associations/add-title-domain-mapping', postData);
};

const AddDomainMapping = ({ isOpen, close }: Props) => {
  const [selectedTitleCompany, setSelectedTitleCompany] = useState<DropdownListItem | null>(null);
  const [titleAssociation, setTitleAssociation] = useState({
    emailDomain: '',
    name: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    fax: '',
  } as Omit<TitleCompanyAssociation, 'associatedId'>);

  const { successToaster, errorToaster } = useToaster();

  const { data: dropdownData } = useQuery(
    ['title-company-dropdown', ''],
    getTitleCompanyDropdownData,
    {
      refetchOnWindowFocus: false,
    },
  );

  const { mutate: submit, isLoading: isSubmitting } = useMutation({
    mutationKey: ['title-association-domain-mapping', selectedTitleCompany, titleAssociation],
    mutationFn: postMapping,
    onSuccess: () => {
      successToaster('Successfully added domain mapping');
      close();
    },
    onError: (e: AxiosError) => {
      const firstError = parseRequestError(e)[0];
      errorToaster(firstError);
    },
  });

  return (
    <form
      className="m2"
      onSubmit={e => {
        e.preventDefault();
        submit({ titleCompanyId: selectedTitleCompany!.id, titleAssociation });
      }}
    >
      <div className="df aic gap-2 mb4 full-width">
        <div>Linked Title Company</div>
        <div className="full-width">
          <Autocomplete
            options={dropdownData || []}
            renderOption={(props, option, state) => [props, option, state.index] as ReactNode}
            onChange={(e, value) => value && setSelectedTitleCompany(value)}
            value={dropdownData?.find(x => x.id === selectedTitleCompany?.id) || null}
            isOptionEqualToValue={(x, y) => x?.id === y?.id}
            autoHighlight
            ListboxComponent={VirtualListComponent}
            fullWidth
            renderInput={params => (
              <TextField placeholder="Select title company" {...params} required />
            )}
          />
        </div>
      </div>

      <div
        style={{
          width: '100%',
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: '16px',
          rowGap: '10px',
        }}
      >
        <div>
          <TextField
            label="Email Domain"
            type="text"
            value={titleAssociation.emailDomain}
            onChange={e => setTitleAssociation(x => ({ ...x, emailDomain: e.target.value }))}
            fullWidth
            required
            inputProps={{ maxLength: 255 }}
          />
        </div>
        <div>
          <TextField
            label="Name"
            type="text"
            value={titleAssociation.name}
            onChange={e => setTitleAssociation(x => ({ ...x, name: e.target.value }))}
            fullWidth
            inputProps={{ maxLength: 255 }}
          />
        </div>
        <div>
          <TextField
            label="Address"
            type="text"
            value={titleAssociation.address}
            onChange={e => setTitleAssociation(x => ({ ...x, address: e.target.value }))}
            fullWidth
            inputProps={{ maxLength: 255 }}
          />
        </div>
        <div>
          <TextField
            label="City"
            type="text"
            value={titleAssociation.city}
            onChange={e => setTitleAssociation(x => ({ ...x, city: e.target.value }))}
            fullWidth
            inputProps={{ maxLength: 255 }}
          />
        </div>
        <div>
          <TextField
            label="State"
            type="text"
            value={titleAssociation.state}
            onChange={e => setTitleAssociation(x => ({ ...x, state: e.target.value }))}
            fullWidth
            inputProps={{ maxLength: 255 }}
          />
        </div>
        <div>
          <TextField
            label="Zip"
            type="text"
            value={titleAssociation.zip}
            onChange={e => setTitleAssociation(x => ({ ...x, zip: e.target.value }))}
            fullWidth
            inputProps={{ maxLength: 255 }}
          />
        </div>
        <div>
          <TextField
            label="Phone"
            type="text"
            value={titleAssociation.phone}
            onChange={e => setTitleAssociation(x => ({ ...x, phone: e.target.value }))}
            fullWidth
            inputProps={{ maxLength: 255 }}
          />
        </div>
        <div>
          <TextField
            label="Fax"
            type="text"
            value={titleAssociation.fax}
            onChange={e => setTitleAssociation(x => ({ ...x, fax: e.target.value }))}
            fullWidth
            inputProps={{ maxLength: 255 }}
          />
        </div>
      </div>

      <Box display="flex" justifyContent="flex-end" sx={{ mt: 2 }}>
        <Button onClick={close} color="primary">
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          color="primary"
          loading={isSubmitting}
          disabled={!canSubmit(selectedTitleCompany?.id, titleAssociation.emailDomain)}
        >
          Submit
        </LoadingButton>
      </Box>
    </form>
  );
};

export default AddDomainMapping;
