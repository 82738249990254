/** @jsx jsx */
import { jsx } from '@emotion/core';
import Link from '@mui/material/Link';
import Datatable from '../../components/ui/DataTableV2/Datatable';
import { Column } from '../../components/ui/DataTableV2/types';

type Props = {
  loanId: number;
};

type RowData = {
  barcodeId: number;
  docType: string;
  matchType: string;
  matchCreatedDate: Date;
  extractedLoanNumber: string;
  extractedDateFunded: Date;
  extractedLoanAmount: number;
  matchQuality: string;
  skipped: boolean;
  deleted: boolean;
  irrelevantType: string;
  uploadedByTitleCompany: boolean;
};

const columns: Column<RowData>[] = [
  {
    id: 'barcodeId',
    label: 'Barcode Id',
    sortable: true,
    render: ({ barcodeId }) => (
      <Link target="_blank" href={`/documents/${barcodeId}`}>
        {barcodeId}
      </Link>
    ),
  },
  {
    id: 'docType',
    label: 'Document Type',
    sortable: true,
  },
  {
    id: 'matchCreatedDate',
    label: 'Matched Date',
    sortable: true,
    render: ({ matchCreatedDate }) =>
      matchCreatedDate === null ? null : new Date(matchCreatedDate).toLocaleDateString(),
  },
  {
    id: 'extractedLoanNumber',
    label: 'Extracted Loan Number',
    sortable: true,
  },
  {
    id: 'extractedDateFunded',
    label: 'Extracted Date Funded',
    sortable: true,
    render: ({ extractedDateFunded }) =>
      extractedDateFunded === null ? null : new Date(extractedDateFunded).toLocaleDateString(),
  },
  {
    id: 'matchQuality',
    label: 'Match Quality',
    sortable: true,
  },
  {
    id: 'matchType',
    label: 'Match Status',
    sortable: true,
  },
  {
    id: 'deleted',
    label: 'Deleted',
    sortable: true,
    render: ({ deleted, irrelevantType }) =>
      deleted === true ? `Yes${irrelevantType ? `, ${irrelevantType}` : ''}` : 'No',
  },
];

const LoanMatches = ({ loanId }: Props) => (
  <Datatable<RowData>
    title="Suggested matches"
    columns={columns}
    url={`/api/loans/${loanId}/SuggestedMatches`}
    exportUrl={`/api/loans/${loanId}/SuggestedMatches`}
    exportFileName="Suggested matches.xlsx"
  />
);

export default LoanMatches;
