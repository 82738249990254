/** @jsx jsx */
import { jsx } from '@emotion/core';
import { RowData } from './CollateralServicesList';
import { NotesDisplay } from '../../components/AccountRepsPage';
import Tooltip from '@mui/material/Tooltip';
import CopyToClipboard from '../../components/CallCenter/TitlePortalOutreach/CopyToClipboard';
import { Link } from '@mui/material';

const currencyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});
export const CollateralServiceDetails = ({ rowData }: { rowData: RowData }) => (
  <div style={{ margin: '16px 48px' }}>
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 2fr',
        gap: '8px',
        marginBottom: '8px',
      }}
    >
      <div className="df col" style={{ gap: '16px' }}>
        <div>
          <b>Borrower: </b>
          {rowData.borrower}
        </div>
        <div>
          <b>Property Address: </b>
          {rowData.address}
        </div>
        <div>
          <b>Title Company: </b>
          {rowData.titleCompany}
        </div>
        <div>
          <b>Title Company Email: </b>
          <Link
            target="_blank"
            href={`https://docprobe.net/title-company-portal/?token=${rowData.titleCompanyToken}`}
          >
            {rowData.titleCompanyEmail}
          </Link>
        </div>
      </div>
      <div className="df col" style={{ gap: '16px' }}>
        <div>
          <b>Inbound Tracking: </b>
          {rowData.inboundTrackingNumber}
        </div>
        <div>
          <b>Date Tracking Added: </b>
          {rowData.trackingAdded && new Date(rowData.trackingAdded).toLocaleDateString()}
        </div>
        <div>
          <b>Last Call Date: </b>
          {rowData.lastCallDate}
        </div>
        <div>
          <b>Memos: </b>
          {rowData.memos}
        </div>
      </div>
      <div className="df col" style={{ gap: '16px' }}>
        <div>
          <b>Outbound Tracking: </b>
          {rowData.outboundTrackingNumber}
        </div>
        <div>
          <b>Shipping Cost: </b>
          {rowData.shippingCost && currencyFormat.format(rowData.shippingCost)}
        </div>
        <div>
          <b>Warehouse Lender: </b>
          {rowData.warehouseLender}
        </div>
        <div>
          <b>Date Shipped: </b>
          {rowData.dateShipped && new Date(rowData.dateShipped).toLocaleDateString()}
        </div>
      </div>
    </div>
  </div>
);
