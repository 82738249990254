/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';
import Link from '@mui/material/Link';
import Datatable from '../../components/ui/DataTableV2/Datatable';
import { Column } from '../../components/ui/DataTableV2/types';

type Props = {
  loanId: number;
};

type RowData = {
  barcodeId: number;
  documentType: string;
  createdDate: Date;
  deletedDate: Date;
  deletedBy: string;
  hardCopy: boolean;
  deletedReason: string;
};

const columns: Column<RowData>[] = [
  {
    id: 'barcodeId',
    label: 'Barcode',
    sortable: true,
    render: ({ barcodeId }) => (
      <Link target="_blank" href={`/documents/${barcodeId}`}>
        {barcodeId}
      </Link>
    ),
  },
  {
    id: 'documentType',
    label: 'Document Type',
    sortable: true,
  },
  {
    id: 'createdDate',
    label: 'Barcode Created',
    sortable: true,
    render: ({ createdDate }) =>
      createdDate === null ? null : new Date(createdDate).toLocaleDateString(),
  },
  {
    id: 'deletedDate',
    label: 'Barcode Deleted',
    sortable: true,
    render: ({ deletedDate }) =>
      deletedDate === null ? null : new Date(deletedDate).toLocaleDateString(),
  },
  {
    id: 'deletedBy',
    label: 'Deleted By',
    sortable: true,
    render: ({ deletedBy }) => (deletedBy === null ? null : deletedBy.split('@')[0]),
  },
  {
    id: 'hardCopy',
    label: 'Hard Copy',
    sortable: true,
    render: ({ hardCopy }) => (hardCopy ? 'Yes' : 'No'),
  },
  {
    id: 'deletedReason',
    label: 'Deleted Reason',
    sortable: true,
  },
  {
    id: 'userReason',
    label: 'User Inputted Reason',
    sortable: true,
  },
];

const DeletedDocuments = ({ loanId }: Props) => {
  return (
    <Fragment>
      <Datatable<RowData>
        title="Deleted documents"
        columns={columns}
        url={`/api/loans/${loanId}/deletedDocuments`}
        exportUrl={`/api/loans/${loanId}/deletedDocuments`}
        exportFileName="Deleted documents.xlsx"
        dense
      />
    </Fragment>
  );
};

export default DeletedDocuments;
