/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useRef, useState } from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Modal from '@mui/material/Modal';
import { CollateralServicesList, RowData } from './CollateralServicesList';
import { TableRef } from '../../components/ui/DataTableV2/Datatable';
import EditInboundTracking from './EditInboundTracking';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalPaper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 750,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    col1: {
      height: '100',
      width: '33%',
      paddingTop: '10',
      paddingRight: '10',
    },
    col2: {
      width: '33%',
      paddingTop: '10',
      paddingRight: '10',
    },
    col3: {
      width: '33%',
    },
  }),
);

export default function CollateralServices() {
  const classes = useStyles();

  const [editTracking, setEditTracking] = useState<RowData | null>(null);

  const tableRef = useRef({} as TableRef);

  function refreshDataTable() {
    const { refreshTable } = tableRef.current;
    refreshTable && refreshTable();
  }

  return (
    <div css={{ margin: 20, maxWidth: 1800 }}>
      {editTracking && (
        <Modal
          onClose={() => {
            setEditTracking(null);
          }}
          open={true}
        >
          <div className={classes.modalPaper}>
            <EditInboundTracking
              onClose={() => {
                setEditTracking(null);
                refreshDataTable();
              }}
              id={editTracking.id}
              inboundTracking={editTracking.inboundTrackingNumber}
            />
          </div>
        </Modal>
      )}

      <CollateralServicesList
        refreshDataTable={refreshDataTable}
        setCollateralService={setEditTracking}
        ref={tableRef}
      />
    </div>
  );
}
