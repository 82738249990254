/** @jsx jsx */
import { jsx } from '@emotion/core';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { Fragment, useRef, useState } from 'react';
import { apiPost } from '../../../adalConfig';
import { parseRequestError } from '../../../Utils';
import { useToaster } from '../../../Hooks/toasters';
import Datatable, { TableRef } from '../../ui/DataTableV2/Datatable';
import { Column } from '../../ui/DataTableV2/types';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

type RowData = {
  id: number;
  client: string;
  datePaid: string;
  paymentAmount: number;
  checkNumber: string;
  canDelete: boolean;
};

const currencyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const ViewPayments = () => {
  const [confirmDeleteRow, setConfirmDeleteRow] = useState<RowData | null>(null);

  const tableRef = useRef({} as TableRef);

  const { successToaster, errorToaster } = useToaster();

  const columns: Column<RowData>[] = [
    {
      id: 'client',
      label: 'Client',
      sortable: true,
    },
    {
      id: 'datePaid',
      label: 'Date paid',
      sortable: true,
      render: ({ datePaid }) => new Date(datePaid).toLocaleDateString(),
    },
    {
      id: 'paymentAmount',
      label: 'Payment amount',
      sortable: true,
      render: ({ paymentAmount }) => currencyFormat.format(paymentAmount),
    },
    {
      id: 'checkNumber',
      label: 'Check number',
      sortable: true,
    },
    {
      id: 'actions',
      label: 'Actions',
      render: rowData =>
        rowData.canDelete ? (
          <Tooltip title="Delete payment">
            <IconButton onClick={() => setConfirmDeleteRow(rowData)}>
              <DeleteIcon color="error" />
            </IconButton>
          </Tooltip>
        ) : (
          <Fragment />
        ),
    },
  ];

  const deletePayment = async () => {
    try {
      await apiPost('/api/charge/delete-payment', { id: confirmDeleteRow?.id });
      successToaster('Successfully deleted payment');
      const { refreshTable } = tableRef.current;
      refreshTable && refreshTable();
      setConfirmDeleteRow(null);
    } catch (e) {
      const firstError = parseRequestError(e)[0];
      errorToaster(firstError);
    }
  };

  return (
    <Fragment>
      <div className="m4">
        <Datatable<RowData>
          title="Payments"
          columns={columns}
          searchBarPlaceholder="Search by client, payment amount, date paid, or check #"
          url="/api/charge/payments"
          exportUrl="/api/charge/payments"
          exportFileName="Payments.xlsx"
          sortConfig={{ field: 'datePaid', direction: 'desc' }}
          ref={tableRef}
        />
      </div>

      <Dialog open={confirmDeleteRow !== null} onClose={() => setConfirmDeleteRow(null)}>
        <DialogTitle>Delete Payment</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please confirm you want to delete a payment of{' '}
            {currencyFormat.format(confirmDeleteRow?.paymentAmount || 0)} from{' '}
            {confirmDeleteRow?.client} made on{' '}
            {new Date(confirmDeleteRow?.datePaid || Date.now()).toLocaleDateString()}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDeleteRow(null)} autoFocus>
            Cancel
          </Button>
          <Button onClick={deletePayment}>Delete</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default ViewPayments;
