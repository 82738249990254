/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useContext, useMemo, useState } from 'react';
import { CompartmentData } from './SortClientDocuments';
import { useGetData } from '../../../../Hooks';
import { useClients } from '../../../../Hooks/useClients';
import { roleTypes } from '../../../../constants';
import { AuthContext } from '../../../AuthContext';
import { groupBy } from 'lodash';
import CircularProgress from '@mui/material/CircularProgress';
import isAfter from 'date-fns/isAfter';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Datatable from '../../../ui/DataTableV2/Datatable';
import { Column, RowDataForExport } from '../../../ui/DataTableV2/types';
import Link from '@mui/material/Link';

type ClientCompartments = {
  clientId: number;
  weeks: string;
  exceptions: string;
};

const groupClientData = (dashboardData: CompartmentData[]): ClientCompartments[] => {
  const groupedByClient = groupBy(dashboardData, d => d.clientId);

  return Object.entries(groupedByClient).map(([clientId, clientData]) => ({
    clientId: parseInt(clientId),
    weeks: clientData
      .filter(d => d.readyToSort)
      .map(d => d.label)
      .join(', '),
    exceptions: clientData
      .filter(d => d.documentsWithExceptions.length > 0)
      .map(d => d.label)
      .join(', '),
  }));
};

const Dashboard = () => {
  const [selectedClientId, setSelectedClientId] = useState(0);

  const { data: dashboardData, isLoading } = useGetData<CompartmentData[]>(
    '/api/documents/sort-dashboard',
    [],
  );

  const clients = useClients();
  const { roles } = useContext(AuthContext);

  const clientsMapping = useMemo(
    () =>
      clients.reduce(
        (prev, current) => prev.set(current.id, current.company),
        new Map<number, string>(),
      ),
    [clients],
  );

  const aggregateData = useMemo(() => groupClientData(dashboardData), [dashboardData]);

  const customExport = (rows: ClientCompartments[]) =>
    rows.map(row => {
      const devColumns = roles.includes(roleTypes.Dev) ? { 'Client Id': row.clientId } : {};

      const otherColumns = {
        Client: clientsMapping.get(row.clientId),
        Shelf: row.weeks,
        Exceptions: row.exceptions,
      };

      return { ...devColumns, ...otherColumns } as RowDataForExport;
    });

  const drilldownCustomExport = (rows: CompartmentData[]) =>
    rows.map(row => {
      const devColumns = roles.includes(roleTypes.Dev)
        ? { 'Compartment Id': row.compartmentId }
        : {};

      const weekOrMonthStart = new Date(row.weekOrMonthStart);

      const otherColumns = {
        Client: clientsMapping.get(row.clientId),
        Shelf: row.label,
        'Week or month start': isAfter(weekOrMonthStart, new Date(2000, 1, 1))
          ? weekOrMonthStart.toLocaleDateString()
          : null,
        'Documents on shelf': `${row.documentIdsReadyForSort.length} of ${row.documentIds.length} docs ready for sort`,
        'Exceptions on shelf': `${row.documentsWithExceptions.length} of ${row.documentIds.length} docs with exceptions`,
      };

      return { ...devColumns, ...otherColumns } as RowDataForExport;
    });

  const columns: Column<ClientCompartments>[] = [
    {
      id: 'clientId',
      label: 'Client Id',
      sortable: true,
      hidden: !roles.includes(roleTypes.Dev),
    },
    {
      id: 'client',
      label: 'Client',
      render: ({ clientId }) => clientsMapping.get(clientId) || null,
    },
    {
      id: 'weeks',
      label: 'Shelf',
      render: ({ clientId, weeks }) => (
        <Link onClick={() => setSelectedClientId(clientId)}>{weeks}</Link>
      ),
    },
    {
      id: 'exceptions',
      label: 'Exceptions',
      render: ({ clientId, exceptions }) => (
        <Link onClick={() => setSelectedClientId(clientId)}>{exceptions}</Link>
      ),
    },
  ];

  const modalColumns: Column<CompartmentData>[] = [
    {
      id: 'compartmentId',
      label: 'Compartment Id',
      sortable: true,
      hidden: !roles.includes(roleTypes.Dev),
    },
    {
      id: 'clientId',
      label: 'Client',
      render: () => clientsMapping.get(selectedClientId) || null,
    },
    {
      id: 'label',
      label: 'shelf',
      sortable: true,
    },
    {
      id: 'weekOrMonthStart',
      label: 'Week or month start',
      render: ({ weekOrMonthStart }) => {
        const startDate = new Date(weekOrMonthStart);
        return isAfter(startDate, new Date(2000, 1, 1)) ? startDate.toLocaleDateString() : null;
      },
    },
    {
      id: 'documentIds',
      label: 'Documents on shelf (ready/total)',
      render: ({ documentIds, documentIdsReadyForSort }) =>
        `${documentIdsReadyForSort.length}/${documentIds.length}`,
    },
    {
      id: 'exceptions',
      label: 'Exceptions on shelf (exceptions/total)',
      render: ({ documentIds, documentsWithExceptions }) =>
        `${documentsWithExceptions.length}/${documentIds.length}`,
    },
  ];

  return (
    <Fragment>
      {!isLoading ? (
        <div className="m4">
          <Datatable<ClientCompartments>
            title="Sort dashboard"
            columns={columns}
            data={aggregateData}
            exportFileName="Sort dashboard.csv"
            customExport={customExport}
          />
        </div>
      ) : (
        <div className="center-in-parent">
          <CircularProgress size="45" disableShrink />
        </div>
      )}

      <Dialog
        open={!!selectedClientId}
        onClose={() => setSelectedClientId(0)}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent>
          <Datatable<CompartmentData>
            title="Sort dashboard drilldown"
            columns={modalColumns}
            data={dashboardData.filter(d => d.clientId === selectedClientId)}
            exportFileName="Sort dashboard drilldown.csv"
            customExport={drilldownCustomExport}
          />
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default Dashboard;
