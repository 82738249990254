/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useContext } from 'react';
import Datatable from '../ui/DataTableV2/Datatable';
import { Column } from '../ui/DataTableV2/types';
import { AuthContext } from '../AuthContext';
import { roleTypes } from '../../constants';
import Link from '@mui/material/Link';

type RowData = {
  associationId: number;
  titleCompanyId: number;
  titleCompany: string;
  emailDomain: string;
  name: string | null;
  address: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  phone: string | null;
  fax: string | null;
  createdAt: string;
  createdBy: string;
};

const ViewDomainMappings = () => {
  const { roles } = useContext(AuthContext);

  const columns: Column<RowData>[] = [
    {
      id: 'associationId',
      label: 'Association id',
      sortable: true,
      hidden: !roles.includes(roleTypes.Dev),
    },
    {
      id: 'titleCompanyId',
      label: 'Title company id',
      sortable: true,
      hidden: !roles.includes(roleTypes.Dev),
    },
    {
      id: 'titleCompany',
      label: 'Title company',
      sortable: true,
      render: ({ titleCompany, titleCompanyId }) => (
        <Link target="_blank" href={`/title-companies/${titleCompanyId}`}>
          {titleCompany}
        </Link>
      ),
    },
    {
      id: 'emailDomain',
      label: 'Email domain',
      sortable: true,
    },
    {
      id: 'name',
      label: 'Name',
      sortable: true,
    },
    {
      id: 'address',
      label: 'Address',
      sortable: true,
    },
    {
      id: 'city',
      label: 'City',
      sortable: true,
    },
    {
      id: 'state',
      label: 'State',
      sortable: true,
    },
    {
      id: 'zip',
      label: 'Zip',
      sortable: true,
    },
    {
      id: 'phone',
      label: 'Phone',
      sortable: true,
    },
    {
      id: 'fax',
      label: 'Fax',
      sortable: true,
    },
    {
      id: 'createdAt',
      label: 'Created at (EST)',
      sortable: true,
      render: ({ createdAt }) => new Date(createdAt).toLocaleString(),
    },
    {
      id: 'createdBy',
      label: 'Created by',
      sortable: true,
    },
  ];

  return (
    <div className="m2">
      <Datatable
        title="Domain mappings"
        columns={columns}
        searchBarPlaceholder="Search by title company id, name or email domain"
        url="/api/associations/domain-mappings"
        exportUrl="/api/associations/domain-mappings"
        exportFileName="Domain mappings.xlsx"
        sortConfig={{ field: 'createdAt', direction: 'desc' }}
        dense
      />
    </div>
  );
};

export default ViewDomainMappings;
