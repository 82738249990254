/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useState } from 'react';
import Link from '@mui/material/Link';
import queryString from 'query-string';
import Button from '@mui/material/Button';
import { format } from 'date-fns';
import {
  CompanyDrillDownData,
  CorrectionsTotalByMonthAndClient,
  CorrectionsTotalByMonthAndUserDrillDown,
} from './types';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Datatable from '../../components/ui/DataTableV2/Datatable';
import { Column, RowDataForExport } from '../../components/ui/DataTableV2/types';

type Props = {
  correctionsTotalByMonthAndClient: CorrectionsTotalByMonthAndClient[];
  fromDate: Date;
  oldVersion: boolean;
};

const drillDownColumns: Column<CorrectionsTotalByMonthAndUserDrillDown>[] = [
  {
    id: 'loanNumber',
    label: 'Loan Number',
    sortable: true,
    render: ({ loanId, loanNumber }) => (
      <Link target="_blank" href={`/loans/${loanId}`}>
        {loanNumber}
      </Link>
    ),
  },
  {
    id: 'documentType',
    label: 'Document Type',
    sortable: true,
  },
  {
    id: 'dateFunded',
    label: 'Date Funded',
    sortable: true,
    render: ({ dateFunded }) => new Date(dateFunded).toLocaleDateString(),
  },
  {
    id: 'dateProcessed',
    label: 'Date Processed',
    sortable: true,
    render: ({ dateProcessed }) => new Date(dateProcessed).toLocaleDateString(),
  },
  {
    id: 'firstDateFailed',
    label: 'Date Audited',
    sortable: false,
    render: ({ firstFailedDate }) => new Date(firstFailedDate).toLocaleDateString(),
  },
  {
    id: 'failedAuditReason',
    label: 'Failed Audit Reason',
    sortable: true,
  },
  {
    id: 'failedAuditNotes',
    label: 'Failed Audit Notes',
    sortable: true,
  },
  {
    id: 'failedVerificationNotes',
    label: 'Failed Verification Notes',
    sortable: true,
  },
  {
    id: 'deletedDate',
    label: '',
    sortable: true,
    render: ({ deletedDate }) =>
      deletedDate ? `deleted on ${new Date(deletedDate).toLocaleDateString()}` : '',
  },
];

const drilldownUrl = (oldVersion: boolean) =>
  `/api/reports/document-corrections-by-client-drill-down-report${oldVersion ? '-old' : ''}`;

const CorrectionsTotalByMonthAndClientReport = ({
  correctionsTotalByMonthAndClient,
  fromDate,
  oldVersion,
}: Props) => {
  const [isAllDrillDownModalOpen, setIsAllDrillDownModalOpen] = useState(false);
  const [companyDrillDownData, setCompanyDrillDownData] = useState<CompanyDrillDownData | null>(
    null,
  );

  const columns: Column<CorrectionsTotalByMonthAndClient>[] = [
    {
      id: 'client',
      label: 'Client',
      sortable: true,
      render: (rowData: CorrectionsTotalByMonthAndClient) => (
        <Link
          css={{ width: '225px', textAlign: 'left' }}
          component="button"
          underline="hover"
          variant="body2"
          onClick={() =>
            setCompanyDrillDownData({
              month: new Date(rowData.month),
              client: rowData.client,
              clientId: rowData.clientId,
            })
          }
        >
          {rowData.client}
        </Link>
      ),
    },
    {
      id: 'totalLoans',
      label: 'Total Loans',
      sortable: true,
    },
    {
      id: 'corrections',
      label: 'Corrections',
      sortable: true,
    },
    {
      id: 'percentDocsFailed',
      label: '% failed',
      sortable: true,
    },
    {
      id: 'tolerancePercent',
      label: 'Tolerance %',
      sortable: true,
      render: ({ tolerancePercent }) => tolerancePercent.toString(),
    },
    {
      id: 'numAbovePercent',
      label: 'Number Above %',
      sortable: true,
      render: ({ numAbovePercent, tolerancePercent }) =>
        tolerancePercent === 0 ? 'n/a' : numAbovePercent.toString(),
    },
    {
      id: 'chargeAmount',
      label: 'Charge Amount',
      sortable: true,
      render: ({ chargeAmount }) =>
        chargeAmount?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) ?? 'n/a',
    },
  ];

  const customExport = (rows: CorrectionsTotalByMonthAndClient[]) =>
    rows.map(
      row =>
        ({
          Client: row.client,
          'Total Docs Processed': row.totalLoans,
          Corrections: row.corrections,
          'Percent Docs Failed': row.percentDocsFailed,
          'Tolerance Percent': row.tolerancePercent === 0 ? 'n/a' : row.tolerancePercent,
          'Number Above Percent': row.tolerancePercent === 0 ? 'n/a' : row.numAbovePercent,
          'Charge Amount':
            row.chargeAmount?.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            }) ?? 'n/a',
        } as RowDataForExport),
    );

  return (
    <Fragment>
      <div style={{ position: 'relative' }}>
        <Button
          style={{
            position: 'absolute',
            top: '42px',
            zIndex: '1',
            right: '105px',
          }}
          onClick={() => setIsAllDrillDownModalOpen(true)}
          color="primary"
          size="small"
          variant="outlined"
        >
          Drilldown
        </Button>
      </div>

      <Datatable<CorrectionsTotalByMonthAndClient>
        title={`Corrections By Month And Client for  ${format(new Date(fromDate), 'MMMM   yyyy')}`}
        columns={columns}
        data={correctionsTotalByMonthAndClient}
        exportFileName={`Corrections_For_${format(new Date(fromDate), 'MMMM_yyyy')}.csv`}
        customExport={customExport}
        sortConfig={{ field: 'client', direction: 'asc' }}
      />

      <Dialog
        open={!!companyDrillDownData}
        onClose={() => setCompanyDrillDownData(null)}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent>
          <Datatable<CorrectionsTotalByMonthAndUserDrillDown>
            title={`Document Corrections Details Report - For ${
              companyDrillDownData?.client
            } - ${format(new Date(fromDate), 'MMMM   yyyy')}`}
            columns={drillDownColumns}
            url={`${drilldownUrl(oldVersion)}?${queryString.stringify({
              clientId: companyDrillDownData?.clientId,
              fromDate: companyDrillDownData?.month.toISOString().split('T')[0],
            })}`}
            exportUrl={`${drilldownUrl(oldVersion)}?${queryString.stringify({
              clientId: companyDrillDownData?.clientId,
              fromDate: companyDrillDownData?.month.toISOString().split('T')[0],
            })}`}
            exportFileName={`${companyDrillDownData?.client}_Corrections_${format(
              new Date(fromDate),
              'MMMM_yyyy',
            )}.xlsx`}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={isAllDrillDownModalOpen}
        onClose={() => setIsAllDrillDownModalOpen(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent>
          <Datatable<CorrectionsTotalByMonthAndUserDrillDown>
            title={`Document Corrections Details Report for ${format(
              new Date(fromDate),
              'MMMM   yyyy',
            )}`}
            columns={drillDownColumns}
            url={`${drilldownUrl(oldVersion)}?${queryString.stringify({
              clientId: null,
              fromDate: fromDate.toISOString().split('T')[0],
            })}`}
            exportUrl={`${drilldownUrl(oldVersion)}?${queryString.stringify({
              clientId: null,
              fromDate: fromDate.toISOString().split('T')[0],
            })}`}
            exportFileName={`All_Clients_Corrections_${format(
              new Date(fromDate),
              'MMMM_yyyy',
            )}.xlsx`}
          />
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default CorrectionsTotalByMonthAndClientReport;
