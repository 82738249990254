/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Dispatch, forwardRef, SetStateAction } from 'react';
import { IconButton, Link, Tooltip } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import { Column, Filter } from '../../components/ui/DataTableV2/types';
import Datatable, { TableRef } from '../../components/ui/DataTableV2/Datatable';
import { CollateralServiceDetails } from './CollateralServiceDetails';
import { DocumentType } from '../../globalTypes/objects';
import { UploadCollateralDocumentForm } from './UploadCollateralDocumentForm';

export type RowData = {
  id: number;
  client: string;
  loanId: number;
  clientId: number;
  loanNumber: string;
  daysAged?: number;
  dateFunded: string;
  closingPackageDocumentId?: number;
  noteDocumentId?: number;
  inboundTrackingNumber: string;
  trackingAdded: string;
  warehouseLender: string;
  titleCompany: string;
  titleCompanyEmail: string;
  titleCompanyToken: string;
  address: string;
  borrower: string;
  shippingCost?: number;
  outboundTrackingNumber: string;
  dateShipped: string;
  lastCallDate?: string;
  memos?: string[];
  createdAt: string;
};

type Props = {
  setCollateralService: Dispatch<SetStateAction<RowData>>;
  refreshDataTable: () => void;
};

const filters: Filter<RowData>[] = [
  {
    filterType: 'dropdown',
    id: 'clients',
    label: 'Clients',
    multiple: true,
    optionsUrl: '/api/clients/filter-dropdown',
    options: [
      {
        label: '',
        value: null,
        active: false,
      },
    ],
  },
  {
    filterType: 'radio',
    id: 'status',
    label: 'Status',
    options: [
      {
        label: 'All',
        value: 0,
        active: true,
      },
      {
        label: 'Outstanding',
        value: 1,
        active: false,
      },
      {
        label: 'Resolved',
        value: 2,
        active: false,
      },
    ],
  },
];

export const CollateralServicesList = forwardRef<TableRef, Props>(
  ({ setCollateralService, refreshDataTable }, ref) => {
    const columns: Column<RowData>[] = [
      {
        id: 'client',
        label: 'Client',
        sortable: true,
      },
      {
        id: 'loanNumber',
        label: 'Loan Number',
        sortable: true,
        render: rowData => (
          <Link target="_blank" href={`/loans/${rowData.loanId}`}>
            {rowData.loanNumber}
          </Link>
        ),
      },
      {
        id: 'closingPackageDocumentId',
        label: 'Closing Package',
        render: rowData => {
          return rowData.closingPackageDocumentId ? (
            <div>
              <Link target="_blank" href={`/documents/${rowData.closingPackageDocumentId}`}>
                {rowData.closingPackageDocumentId}
              </Link>
            </div>
          ) : (
            <UploadCollateralDocumentForm
              documentType={DocumentType.ClosingPackage}
              loanId={rowData.loanId}
              clientId={rowData.clientId}
              onUploadSuccess={() => refreshDataTable()}
            />
          );
        },
      },
      {
        id: 'noteDocumentId',
        label: 'Note',
        render: rowData => {
          return rowData.noteDocumentId ? (
            <Link target="_blank" href={`/documents/${rowData.noteDocumentId}`}>
              {rowData.noteDocumentId}
            </Link>
          ) : (
            <UploadCollateralDocumentForm
              documentType={DocumentType.Note}
              loanId={rowData.loanId}
              clientId={rowData.clientId}
              onUploadSuccess={() => refreshDataTable()}
            />
          );
        },
      },
      {
        id: 'dateFunded',
        label: 'Date Funded',
        sortable: true,
        render: ({ dateFunded }) => new Date(dateFunded).toLocaleDateString(),
      },
      {
        id: 'daysAged',
        label: 'Days Aged',
        sortable: true,
      },
      {
        id: 'createdAt',
        label: 'Date Added',
        sortable: true,
        render: ({ createdAt }) => new Date(createdAt).toLocaleDateString(),
      },
      {
        id: 'actions',
        label: 'Actions',
        render: rowData => (
          <Tooltip title="Edit Tracking Information">
            <IconButton
              onClick={() => {
                setCollateralService(rowData);
              }}
            >
              <CreateIcon color="primary" />
            </IconButton>
          </Tooltip>
        ),
      },
    ];

    return (
      <Datatable<RowData>
        title="Collateral Services"
        columns={columns}
        filters={filters}
        url="/api/closingPackages/getCollateralServices"
        exportUrl="/api/closingPackages/getCollateralServices"
        exportFileName="Collateral Services.xlsx"
        searchBarPlaceholder="Search by loan #, inbound tracking #, or outbound tracking #"
        sortConfig={{ field: 'createdAt', direction: 'desc' }}
        ref={ref}
        detailsPanel={rowData => <CollateralServiceDetails rowData={rowData} />}
      />
    );
  },
);
